import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import PlayerColor from '../players/PlayerColor'
import TokenAction from '../tokens/TokenAction'
import MoveType from './MoveType'

type ChooseAction = {
  type: MoveType.ChooseAction
  player: PlayerColor
  action: TokenAction
  district: District
}

export default ChooseAction

export function chooseActionMove(player: PlayerColor, action: TokenAction, district: District): ChooseAction {
  return {type: MoveType.ChooseAction, player, action, district}
}

export function chooseAction(state: GameState | GameView, move: ChooseAction) {
  const player = state.players.find(p => p.color === move.player)!
  player.action = move.action
}
