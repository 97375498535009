/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {Picture} from '@gamepark/react-components'
import {PictureAttributes} from '@gamepark/react-components/dist/Picture/Picture'
import Images from '../../images/Images'

type Props = {
  playerColor: PlayerColor
} & PictureAttributes

export default function ActionToken({playerColor, ...props}: Props) {
  return <Picture src={actionTokenImage[playerColor]} css={actionTokenCss} {...props}/>
}

const actionTokenImage: Record<PlayerColor, string> = {
  [PlayerColor.White]: Images.actionTokenWhite,
  [PlayerColor.Blue]: Images.actionTokenBlue,
  [PlayerColor.Red]: Images.actionTokenRed,
  [PlayerColor.Green]: Images.actionTokenGreen,
  [PlayerColor.Purple]: Images.actionTokenPurple,
  [PlayerColor.Yellow]: Images.actionTokenYellow
}

export const actionTokenSize = 5

export const actionTokenCss = css`
  border-radius: 50%;
  box-shadow: 0 0 0.5em black;
  width: ${actionTokenSize}em;
  height: ${actionTokenSize}em;
`
