import {MAX_TOKENS, STOCK, TokenLocation} from '../players/PlayerCommon'

type TokenView = {
  id: number
  location?: TokenLocation
  position: number
}

export default TokenView

export function createTokensPlace(tokens: TokenLocation[]): TokenView[] {
  const tokenViews: TokenView[] = []
  let stockPlace = 0
  for (const location of tokens) {
    if (location === STOCK) {
      tokenViews.push({id: tokenViews.length, location, position: stockPlace++})
    } else {
      tokenViews.push({id: tokenViews.length, location, position: 0})
    }
  }
  while (tokenViews.length < MAX_TOKENS) {
    tokenViews.push({id: tokenViews.length, position: MAX_TOKENS - tokenViews.length - 1})
  }
  return tokenViews
}
