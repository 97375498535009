import GameView from '@gamepark/brigands/GameView'
import {TokenLocation} from '@gamepark/brigands/players/PlayerCommon'

export const SELECT_TOKEN = 'SelectToken'

export default interface SelectToken {
  type: typeof SELECT_TOKEN
  location?: TokenLocation
}

export function selectTokenMove(location?: TokenLocation): SelectToken {
  return {type: SELECT_TOKEN, location}
}

export function selectToken(state: GameView, move: SelectToken) {
  delete state.selectedMeeple
  state.selectedToken = move.location
}
