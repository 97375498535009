import {shuffle} from 'lodash'
import District, {thievesDistricts} from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import PlayerColor from '../players/PlayerColor'
import ThiefState from '../players/ThiefState'
import ThiefView from '../players/ThiefView'
import MoveType from './MoveType'

type SpyDistrictCards = {
  type: typeof MoveType.SpyDistrictCards
  thief: PlayerColor
  spy: number
}

export default SpyDistrictCards

export function spyDistrictCardsMove(thief: PlayerColor, spy: number): SpyDistrictCards {
  return {type: MoveType.SpyDistrictCards, thief, spy}
}

export type SpyDistrictCardsRandomized = SpyDistrictCards & {
  districts: District[]
}

export function randomizeSpyDistrictCards(state: GameState, move: SpyDistrictCards): SpyDistrictCardsRandomized {
  const thief = state.players.find(player => player.color === move.thief) as ThiefState
  return {...move, districts: shuffle(thievesDistricts.filter(district => !thief.spied.includes(district))).slice(0, move.spy)}
}

export function spyDistrictCards(state: GameState, move: SpyDistrictCardsRandomized) {
  const thief = state.players.find(player => move.thief === player.color) as ThiefState
  thief.spied.push(...move.districts)
}

export function spyDistrictCardsInView(state: GameView, move: SpyDistrictCards | SpyDistrictCardsRandomized) {
  const thief = state.players.find(player => move.thief === player.color) as ThiefView
  if (isSpyDistrictCardsRandomized(move)) {
    thief.spied.push(...move.districts)
  } else {
    thief.spied.push(...Array(move.spy))
  }
}

export function isSpyDistrictCardsRandomized(move: SpyDistrictCards | SpyDistrictCardsRandomized): move is SpyDistrictCardsRandomized {
  return (move as SpyDistrictCardsRandomized).districts !== undefined
}
