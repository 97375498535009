import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import Move from '../moves/Move'
import ActionType from './ActionType'
import goHome from './GoHome'
import moveToNextDistrict from './MoveToNextDistrict'
import pushToNextDistrict from './PushToNextDistrict'
import spy from './Spy'
import steal from './Steal'

export default function getActionMoves(game: GameState | GameView, district: District, actionType: ActionType): Move[] {
  switch (actionType) {
    case ActionType.Spy:
      return spy(game, district)
    case ActionType.GoHome:
      return goHome(game, district)
    case ActionType.Steal:
      return steal(game, district)
    case ActionType.Move:
      return moveToNextDistrict(game, district)
    case ActionType.Push:
      return pushToNextDistrict(game, district)
    default:
      return []
  }
}