import GameView from '@gamepark/brigands/GameView'

export const SELECT_MEEPLE = 'SelectMeeple'

export default interface SelectMeeple {
  type: typeof SELECT_MEEPLE
  meeple?: number
}

export const selectMeepleMove = (meeple?: number): SelectMeeple => ({
  type: SELECT_MEEPLE, meeple
})

export function selectMeeple(state: GameView, move: SelectMeeple) {
  delete state.selectedToken
  state.selectedMeeple = move.meeple
}
