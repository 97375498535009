/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import District from '@gamepark/brigands/districts/District'
import {TAVERN_COST} from '@gamepark/brigands/districts/Tavern'
import Move from '@gamepark/brigands/moves/Move'
import MoveType from '@gamepark/brigands/moves/MoveType'
import {stealGoldMove} from '@gamepark/brigands/moves/StealGold'
import {throwDicesMove} from '@gamepark/brigands/moves/ThrowDices'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {usePlayerId} from '@gamepark/react-client'
import {Dialog, DialogProps, Picture} from '@gamepark/react-components'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import Images from '../../images/Images'
import Button from '../../utils/Button'
import {dialogCloseIcon, dialogCss} from '../../utils/styles'

type Props = {
  gold: number
  close: () => void
  play: (move: Move) => void
} & DialogProps

export default function TavernDialog({gold, close, play, ...props}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()!
  const [selectedMove, setSelectedMove] = useState<Move>()
  return (
    <Dialog css={dialogCss} {...props} onBackdropClick={close}>
      <h2>{t('district.choice.title')}</h2>
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={close}/>
      <div css={flexContainer}>
        <Picture src={Images.tavernRoll1} onClick={() => setSelectedMove(throwDicesMove(1, District.Tavern, playerId))}
                 css={selectedMove?.type === MoveType.ThrowDices && selectedCss}/>
        <Picture src={Images.tavernRoll3}
                 onClick={() => gold >= TAVERN_COST && setSelectedMove(stealGoldMove(playerId, -TAVERN_COST, District.Tavern))}
                 css={[selectedMove?.type === MoveType.StealGold && selectedCss, gold < TAVERN_COST && unavailable]}/>
      </div>
      <Button playerColor={playerId} disabled={!selectedMove || (selectedMove.type === MoveType.StealGold && gold < TAVERN_COST)}
              onClick={() => selectedMove && play(selectedMove)}>
        {!selectedMove ? t('select.action')
          : selectedMove?.type === MoveType.ThrowDices ? t('tavern.choice.roll')
            : t('tavern.choice.bet')}
      </Button>
    </Dialog>
  )
}

const actionWidth = 40

const flexContainer = css`
  display: flex;
  max-width: 180em;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3em;

  img {
    margin: 1em;
    width: ${actionWidth}em;
    height: ${actionWidth * 220 / 465}em;
    cursor: pointer;
  }
`

const selectedCss = css`
  filter: drop-shadow(0.5em 0 darkgreen) drop-shadow(-0.5em 0 darkgreen) drop-shadow(0 0.5em darkgreen) drop-shadow(0 -0.5em darkgreen);
`

const unavailable = css`
  cursor: auto !important;
  filter: grayscale();
`