/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {ButtonHTMLAttributes} from 'react'

export type HeaderButtonProps = {
  color: PlayerColor
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'color'>

export default function HeaderButton({children, color, ...props}: HeaderButtonProps) {
  return <button css={[style, buttonColor(color)]} {...props}>{children}</button>
}


const style = css`
  padding: 0 0.4em;
  display: inline-block;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  cursor: pointer;
  overflow: hidden;
  color: black;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  outline: 0;
  border-style: none;
  border-radius: 0.5em;
`

const buttonColor = (color: PlayerColor) => css`
  background-color: ${buttonBackgroundColor[color]};
  color: ${color === PlayerColor.Purple || color === PlayerColor.Red || color === PlayerColor.Blue ? 'white' : 'black'};
`

const buttonBackgroundColor: Record<PlayerColor, string> = {
  [PlayerColor.White]: '#f0c89b',
  [PlayerColor.Blue]: '#0090ff',
  [PlayerColor.Red]: '#fe0000',
  [PlayerColor.Green]: '#61a420',
  [PlayerColor.Purple]: '#a100fe',
  [PlayerColor.Yellow]: '#fea500'
}