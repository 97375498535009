import {Action, Competitive, RandomMove, Rules, SecretInformation, TimeLimit, Undo} from '@gamepark/rules-api'
import {shuffle} from 'lodash'
import {BrigandsOptions, BrigandsPlayerOptions, isGameOptions} from './BrigandsOptions'
import canUndo from './canUndo'
import CityHall from './districts/CityHall'
import Convoy from './districts/Convoy'
import District, {districts, thievesDistricts} from './districts/District'
import Harbor from './districts/Harbor'
import Jail from './districts/Jail'
import Market from './districts/Market'
import Palace from './districts/Palace'
import Tavern from './districts/Tavern'
import Treasure from './districts/Treasure'
import GameState from './GameState'
import GameView from './GameView'
import {chooseAction, chooseActionMove} from './moves/ChooseAction'
import {discardDayCard} from './moves/DiscardDayCard'
import {discardToken, discardTokenMove} from './moves/DiscardToken'
import {drawDayCard, drawDayCardMove, getDrawEventView} from './moves/DrawDayCard'
import Move from './moves/Move'
import {moveOnNextPhase, moveOnNextPhaseMove} from './moves/MoveOnNextPhase'
import MoveRandomized from './moves/MoveRandomized'
import MoveType from './moves/MoveType'
import MoveView from './moves/MoveView'
import {pass, passMove} from './moves/Pass'
import {placeMeeple, placeMeepleMove} from './moves/PlaceMeeple'
import {placeToken, placeTokenMove} from './moves/PlaceToken'
import {spendTokens} from './moves/SpendTokens'
import {randomizeSpyDistrictCards, spyDistrictCards} from './moves/SpyDistrictCards'
import {stealGold} from './moves/StealGold'
import {takeBackMeeple, takeBackMeepleMove} from './moves/TakeBackMeeple'
import {takeToken, takeTokenMove} from './moves/TakeToken'
import {randomizeThrowDices, throwDices} from './moves/ThrowDices'
import {upgradeSkill} from './moves/UpgradeSkill'
import Phase from './Phase'
import isPrince from './players/IsPrince'
import isThief from './players/IsThief'
import PlayerColor from './players/PlayerColor'
import {MAX_TOKENS, STOCK} from './players/PlayerCommon'
import PlayerState, {PLAYER_MEEPLES} from './players/PlayerState'
import PlayerView from './players/PlayerView'
import PrinceState from './players/PrinceState'
import ThiefState, {GOLD_TO_WIN} from './players/ThiefState'
import ActionType, {actionTypes} from './tokens/ActionType'
import getActionMoves from './tokens/GetActionMoves'
import {createTokensPlace} from './tokens/TokenView'

export default class Brigands extends Rules<GameState, Move, PlayerColor>
  implements SecretInformation<GameView, Move, MoveView, PlayerColor>,
    RandomMove<Move, MoveRandomized>,
    Undo<GameState, Move, PlayerColor>,
    TimeLimit<GameState, Move, PlayerColor>,
    Competitive<GameState, Move, PlayerColor> {

  constructor(state: GameState)
  constructor(options: BrigandsOptions)
  constructor(arg: GameState | BrigandsOptions) {
    if (isGameOptions(arg)) {
      const game: GameState = {
        players: setupPlayers(arg.players),
        city: shuffle(thievesDistricts),
        phase: Phase.NewDay,
        deck: shuffle(thievesDistricts).slice(0, NUMBER_OF_DAYS),
        dayCards: [],
        nextMoves: [],
        tutorial: false
      }
      super(game)
    } else {
      super(arg)
    }
  }

  isOver(): boolean {
    return this.state.phase === Phase.NewDay && this.state.deck.length === 0
  }

  isTurnToPlay(playerId: PlayerColor): boolean {
    return isTurnToPlay(this.state, playerId)
  }

  getLegalMoves(playerId: PlayerColor): Move[] {
    const player = this.state.players.find(player => player.color === playerId)!
    switch (this.state.phase) {
      case Phase.Planning:
        if (player.ready) return []
        const moves: Move[] = []
        if (!player.meeples.includes(STOCK)) {
          moves.push(passMove(playerId))
        }
        for (const district of districts) {
          for (let meeple = 0; meeple < player.meeples.length; meeple++) {
            if (player.meeples[meeple] === STOCK && canPlaceMeepleInDistrict(player, district)) {
              moves.push(placeMeepleMove(playerId, meeple, district))
            }
          }
          if (player.tokens.includes(STOCK) && canPlaceTokenInDistrict(player, district)) {
            moves.push(placeTokenMove(playerId, district))
          }
        }
        return moves
      case Phase.Solving:
        const district = getCurrentDistrict(this.state)
        if (!player.meeples.includes(district)) {
          return []
        }
        if (!player.action && player.tokens.includes(district)) {
          const moves: Move[] = []
          const otherThieves = this.state.players.filter(player =>
            player.color !== playerId && player.color !== PlayerColor.White && player.meeples.slice(0, PLAYER_MEEPLES).includes(district)
          )
          if (playerId === PlayerColor.White && otherThieves.length > 0) {
            moves.push(chooseActionMove(playerId, {type: ActionType.Spy}, district))
          }
          moves.push(chooseActionMove(playerId, {type: ActionType.GoHome}, district))
          for (const otherThief of otherThieves) {
            moves.push(chooseActionMove(playerId, {type: ActionType.Steal, target: otherThief.color}, district))
          }
          if (district !== District.Jail) {
            moves.push(chooseActionMove(playerId, {type: ActionType.Move}, district))
          }
          if (playerId !== PlayerColor.White) {
            for (const otherThief of otherThieves) {
              moves.push(chooseActionMove(playerId, {type: ActionType.Push, target: otherThief.color}, district))
            }
          }
          moves.push(chooseActionMove(playerId, {type: ActionType.Pass}, district))
          return moves
        }
        return getDistrictRules(this.state, district).getLegalMoves(player)
      default:
        return []
    }
  }

  getAutomaticMoves(): Move[] {
    if (this.state.nextMoves.length > 0) {
      return [this.state.nextMoves[0]]
    }
    switch (this.state.phase) {
      case Phase.NewDay:
        if (this.state.deck.length === 0) {
          return []
        }
        return [...this.state.players.filter(p => p.tokens.length < MAX_TOKENS).map(p => takeTokenMove(p.color)), drawDayCardMove, moveOnNextPhaseMove]
      case Phase.Planning:
        return this.state.players.every(player => player.ready) ? [moveOnNextPhaseMove] : []
      case Phase.Solving:
        const district = getCurrentDistrict(this.state)
        if (this.state.players.some(player => player.tokens.includes(district) && !player.action)) {
          return []
        }
        for (const actionType of actionTypes) {
          const players = this.state.players.filter(player => player.action?.type === actionType)
          if (players.length > 0) {
            const moves = getActionMoves(this.state, district, actionType)
            for (const player of players) {
              moves.push(discardTokenMove(player.color))
            }
            return moves
          }
        }
        if (district !== District.Jail && patrolInDistrict(this.state, district)) {
          return arrestEveryone(this.state, district)
        }
        return getDistrictRules(this.state, district).getAutomaticMoves()
      default:
        return []
    }
  }

  randomize(move: Move): Move & MoveRandomized {
    switch (move.type) {
      case MoveType.ThrowDices:
        return randomizeThrowDices(move)
      case MoveType.SpyDistrictCards:
        return randomizeSpyDistrictCards(this.state, move)
      default:
        return move
    }
  }

  play(move: MoveRandomized): Move[] {
    if (this.state.nextMoves.length && this.state.nextMoves[0].type === move.type) {
      this.state.nextMoves.shift()
    }
    switch (move.type) {
      case MoveType.TakeToken:
        takeToken(this.state, move)
        break
      case MoveType.DrawDayCard:
        drawDayCard(this.state)
        break
      case MoveType.MoveOnNextPhase:
        moveOnNextPhase(this.state)
        break
      case MoveType.PlaceMeeple:
        placeMeeple(this.state, move)
        break
      case MoveType.PlaceToken:
        placeToken(this.state, move)
        break
      case MoveType.Pass:
        pass(this.state, move)
        break
      case MoveType.ChooseAction:
        chooseAction(this.state, move)
        break
      case MoveType.TakeBackMeeple:
        takeBackMeeple(this.state, move)
        break
      case MoveType.StealGold:
        stealGold(this.state, move)
        break
      case MoveType.DiscardToken:
        discardToken(this.state, move)
        break
      case MoveType.SpendTokens:
        spendTokens(this.state, move)
        break
      case MoveType.ThrowDices:
        throwDices(this.state, move)
        break
      case MoveType.DiscardDayCard:
        discardDayCard(this.state, move)
        break
      case MoveType.UpgradeSkill:
        upgradeSkill(this.state, move)
        break
      case MoveType.SpyDistrictCards:
        spyDistrictCards(this.state, move)
        break
    }
    return []
  }

  getView(playerId?: PlayerColor): GameView {
    return {
      ...this.state, deck: this.state.deck.length,
      players: this.state.players.map(player => {
        if (isThief(player)) {
          return {
            ...player,
            tokensPlace: createTokensPlace(player.tokens),
            spied: playerId === player.color || playerId === PlayerColor.White ? player.spied : player.spied.map(() => undefined)
          }
        } else {
          return {...player, tokensPlace: createTokensPlace(player.tokens)}
        }
      })
    }
  }


  getPlayerView(playerId: PlayerColor): GameView {
    return this.getView(playerId)
  }

  getMoveView(move: MoveRandomized, playerId?: PlayerColor): MoveView {
    switch (move.type) {
      case MoveType.DrawDayCard:
        return getDrawEventView(this.state)
      case MoveType.SpyDistrictCards:
        if (playerId === PlayerColor.White || playerId === move.thief) {
          return move
        } else {
          const {districts, ...moveView} = move
          return moveView
        }
      default:
        return move
    }
  }

  getPlayerMoveView(move: MoveRandomized, playerId: PlayerColor): MoveView {
    return this.getMoveView(move, playerId)
  }

  keepMoveSecret(move: MoveRandomized): boolean {
    switch (move.type) {
      case MoveType.PlaceMeeple:
      case MoveType.PlaceToken:
        return this.state.phase === Phase.Planning
      case MoveType.ChooseAction:
        return this.state.players.find(player => player.color === move.player)!.action !== undefined
      case MoveType.StealGold:
      case MoveType.ThrowDices:
      case MoveType.SpendTokens:
      case MoveType.TakeToken:
        return this.state.phase === Phase.Solving && getCurrentDistrict(this.state) === move.district
      default:
        return false
    }
  }

  canUndo(action: Action<MoveRandomized, PlayerColor>, consecutiveActions: Action<MoveRandomized, PlayerColor>[]): boolean {
    return canUndo(action, consecutiveActions, this.state)
  }

  giveTime(): number {
    switch (this.state.phase) {
      case Phase.Planning:
        return 120
      case Phase.Solving:
        return 30
      default:
        return 0
    }
  }

  rankPlayers(playerColorA: PlayerColor, playerColorB: PlayerColor): number {
    const playerA = this.state.players.find(player => player.color === playerColorA)
    const playerB = this.state.players.find(player => player.color === playerColorB)
    const goldA = playerA && isThief(playerA) ? playerA.gold : GOLD_TO_WIN - 0.1
    const goldB = playerB && isThief(playerB) ? playerB.gold : GOLD_TO_WIN - 0.1
    return goldB - goldA
  }
}

const NUMBER_OF_DAYS = 6

export const setupPlayers = (players: BrigandsPlayerOptions[]) => players.map(({id}) =>
  id === PlayerColor.White ? setupPrince() : setupThief(id, players.length === 2))

export const setupPrince = (): PrinceState => ({color: PlayerColor.White, meeples: Array(3).fill(STOCK), tokens: [], spy: 1, judge: 0})

export const setupThief = (color: Exclude<PlayerColor, PlayerColor.White>, duel: boolean): ThiefState =>
  ({color, meeples: Array(duel ? 4 : 3).fill(STOCK), tokens: [], gold: 0, spied: []})

export function canPlaceMeepleInDistrict(player: PlayerState | PlayerView, district: District) {
  if (isPrince(player)) return true
  return district !== District.Jail && !player.spied.includes(district)
}

export function canPlaceTokenInDistrict(player: PlayerState | PlayerView, district: District) {
  return canPlaceMeepleInDistrict(player, district) && player.meeples.slice(0, PLAYER_MEEPLES).includes(district) && !player.tokens.includes(district)
}

export function getCurrentDistrict(state: GameState | GameView) {
  return state.city.find(district => state.players.some(player => player.meeples.includes(district))) ?? District.Jail
}

export function patrolInDistrict(state: GameState | GameView, district: District) {
  if (state.players.length === 2) {
    return state.players.some(player => player.meeples.slice(PLAYER_MEEPLES).includes(district))
  } else {
    const prince = state.players.find(player => player.color === PlayerColor.White)!
    return prince.meeples.includes(district)
  }
}

export function getDistrictRules(state: GameState | GameView, district: District = getCurrentDistrict(state)) {
  switch (district) {
    case District.Jail:
      return new Jail(state)
    case District.Tavern:
      return new Tavern(state)
    case District.Convoy:
      return new Convoy(state)
    case District.Market:
      return new Market(state)
    case District.Palace:
      return new Palace(state)
    case District.CityHall:
      return new CityHall(state)
    case District.Harbor:
      return new Harbor(state)
    case District.Treasure:
      return new Treasure(state)
  }
}

export function arrestEveryone(state: GameState | GameView, district: District) {
  const moves: Move[] = []
  for (const player of state.players) {
    for (let meeple = 0; meeple < player.meeples.length; meeple++) {
      const meepleLocation = player.meeples[meeple]
      if (meepleLocation === district) {
        if (player.color !== PlayerColor.White && meeple < PLAYER_MEEPLES) {
          moves.unshift(placeMeepleMove(player.color, meeple, District.Jail))
        } else {
          moves.push(takeBackMeepleMove(player.color, meeple))
        }
      }
    }
  }
  return moves
}

export function countPlayerMeeples(player: PlayerState | PlayerView, district: District) {
  return player.meeples.slice(0, PLAYER_MEEPLES).reduce((sum, meeple) => meeple === district ? sum + 1 : sum, 0)
}

export function countMeeples(state: GameState | GameView, district: District) {
  let meeples = 0
  for (const player of state.players) {
    meeples += countPlayerMeeples(player, district)
  }
  return meeples
}

export function isTurnToPlay(state: GameState | GameView, playerId: PlayerColor): boolean {
  const player = state.players.find(player => player.color === playerId)!
  switch (state.phase) {
    case Phase.Planning:
      return !player.ready
    case Phase.Solving:
      const district = getCurrentDistrict(state)
      if (state.players.some(player => player.tokens.includes(district))) {
        return player.tokens.includes(district) && !player.action
      } else {
        return getDistrictRules(state, district).isTurnToPlay(player)
      }
    default:
      return false
  }
}