import District from '@gamepark/brigands/districts/District'
import GameView from '@gamepark/brigands/GameView'
import MoveType from '@gamepark/brigands/moves/MoveType'
import {placeTokenMove} from '@gamepark/brigands/moves/PlaceToken'
import {TokenLocation} from '@gamepark/brigands/players/PlayerCommon'
import {useGame, usePlay, usePlayerId, useUndo} from '@gamepark/react-client'
import {selectTokenMove} from '../../localMoves/SelectToken'

export default function usePlayPlaceToken() {
  const play = usePlay()
  const [undo] = useUndo()
  const game = useGame<GameView>()
  const playerId = usePlayerId()
  const player = game?.players.find(player => player.color === playerId)
  return (district: District, currentLocation?: TokenLocation) => {
    if (!player) return
    if (currentLocation) {
      undo(pastMove => pastMove.type === MoveType.PlaceToken && pastMove.district === currentLocation)
      play(selectTokenMove(currentLocation), {local: true})
    }
    play(placeTokenMove(player.color, district))
  }
}