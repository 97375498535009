import {patrolInDistrict} from '../Brigands'
import Move from '../moves/Move'
import {moveOnNextPhaseMove} from '../moves/MoveOnNextPhase'
import SpendTokens, {spendTokensMove} from '../moves/SpendTokens'
import TakeToken, {takeTokenMove} from '../moves/TakeToken'
import {Skill, upgradeSkillMove} from '../moves/UpgradeSkill'
import isPrince from '../players/IsPrince'
import isThief from '../players/IsThief'
import PlayerColor from '../players/PlayerColor'
import {MAX_TOKENS} from '../players/PlayerCommon'
import PlayerState from '../players/PlayerState'
import PrinceState from '../players/PrinceState'
import PrinceView from '../players/PrinceView'
import ThiefState from '../players/ThiefState'
import judge from '../skills/Judge'
import District from './District'
import {DistrictRules} from './DistrictRules'

export default class Jail extends DistrictRules {
  district = District.Jail

  getAutomaticMoves(): Move[] {
    if (patrolInDistrict(this.state, this.district)) {
      const prince = this.state.players.find(isPrince) as PrinceState | PrinceView
      if (prince.spy === 3 && prince.judge === 3) {
        return judge(this.state)
      }
    } else if (this.allMeeplesSolved()) {
      return [moveOnNextPhaseMove]
    }
    return []
  }

  isTurnToPlay(player: PlayerState): boolean {
    if (patrolInDistrict(this.state, this.district)) {
      return player.color === PlayerColor.White
    } else {
      return isThief(player) && this.isThiefTurnToPlay(player)
    }
  }

  allMeeplesSolved() {
    for (const player of this.state.players) {
      if (isThief(player) && this.isThiefTurnToPlay(player)) {
        return false
      }
    }
    return true
  }

  isThiefTurnToPlay(thief: ThiefState) {
    return this.countPlayerMeeples(thief) > (thief.meeplesStayingInJail ?? 0)
  }

  getLegalMoves(player: PlayerState): Move[] {
    const moves: Move[] = []
    if (patrolInDistrict(this.state, this.district)) {
      if (isPrince(player)) {
        if (player.spy < 3) {
          moves.push(upgradeSkillMove(Skill.Spy))
        }
        if (player.judge < 3) {
          moves.push(upgradeSkillMove(Skill.Judge))
        }
      }
    } else {
      if (!isPrince(player) && (!player.meeplesStayingInJail || this.countPlayerMeeples(player) > player.meeplesStayingInJail)) {
        if (player.tokens.length > 0) {
          moves.push(spendTokensMove(player.color, this.district))
        }
        if (player.tokens.length < MAX_TOKENS) {
          moves.push(takeTokenMove(player.color, this.district))
        }
      }
    }
    return moves
  }

  onSpendTokens(move: SpendTokens) {
    return this.takeBackMeeple(move.player)
  }

  onTakeToken(move: TakeToken) {
    const player = this.state.players.find(player => player.color === move.player)!
    if (!isPrince(player)) {
      player.meeplesStayingInJail = (player.meeplesStayingInJail ?? 0) + 1
    }
  }
}