/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import isThief from '@gamepark/brigands/players/IsThief'
import PlayerView from '@gamepark/brigands/players/PlayerView'
import ThiefView from '@gamepark/brigands/players/ThiefView'
import {Hand} from '@gamepark/react-components'
import {princeBoardLeft, princeBoardWidth} from '../PrinceBoard'
import DistrictCard, {districtCardHeight, districtCardRatio, districtCardWidth} from './DistrictCard'
import {useAnimation, usePlayerId} from '@gamepark/react-client'
import MoveType from '@gamepark/brigands/moves/MoveType'
import {SpyDistrictCardsRandomized} from '@gamepark/brigands/moves/SpyDistrictCards'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {useMemo} from 'react'
import PlayerPosition, {getPlayerPosition} from '../PlayerPosition'
import {playerPanelHeight, playerPanelWidth, playerPanelX, playerPanelY} from '../../utils/styles'

type Props = {
  players: PlayerView[]
  onCardClick?: () => void
}

export default function PrinceHand({players, onCardClick}: Props) {
  const animation = useAnimation<SpyDistrictCardsRandomized, PlayerColor>(animation => animation.move.type === MoveType.SpyDistrictCards)
  const cards = useMemo(() =>
      players.filter<ThiefView>(isThief).flatMap(thief => thief.spied.map((district, index) => ({
        color: thief.color,
        district, index,
        animate: animation?.move.thief === thief.color && index >= thief.spied.length - animation.move.spy && animation.duration
      }))),
    [players, animation])
  const playerId = usePlayerId<PlayerColor>()
  return (
    <Hand css={handCss} sizeRatio={districtCardRatio} maxAngle={12} gapMaxAngle={3.5} getItemProps={index => ({
      onClick: onCardClick,
      animation: animation && {
        seconds: animation.duration,
        fromNeutralPosition: !!cards[index].animate
      }
    })}>
      {cards.map(card =>
        <DistrictCard key={`${card.color}_${card.district ?? card.index}`} playerColor={card.color} district={card.district}
                      css={card.animate && animateFrom(getPlayerPosition(players, card.color, playerId), card.animate)}/>
      )}
    </Hand>
  )
}

const handX = princeBoardLeft + princeBoardWidth / 2 - districtCardWidth / 2
const handY = 100 - 1 - districtCardHeight

const handCss = css`
  position: absolute;
  left: ${handX}em;
  top: ${handY}em;
  width: ${districtCardWidth}em;
  height: ${districtCardHeight}em;
  cursor: help;
`

const takeCardKeyframes = (position: PlayerPosition) => keyframes`
  from {
    transform: translate(${(playerPanelX(position) - handX + playerPanelWidth / 2 - districtCardWidth / 2)}em, ${(playerPanelY(position) - handY + playerPanelHeight / 2 - districtCardHeight / 2)}em);
  }
`

const animateFrom = (position: PlayerPosition, duration: number) => css`
  animation: ${takeCardKeyframes(position)} ${duration}s ease-in-out forwards;
`
