import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import SpyDistrictCards, {spyDistrictCardsMove} from '../moves/SpyDistrictCards'
import isPrince from '../players/IsPrince'
import PrinceState from '../players/PrinceState'
import PrinceView from '../players/PrinceView'
import ActionType from './ActionType'
import isThief from '../players/IsThief'

const SPY_MAX = 3

export default function spy(game: GameState | GameView, district: District): SpyDistrictCards[] {
  const moves: SpyDistrictCards[] = []
  const prince = game.players.find(isPrince) as PrinceState | PrinceView
  if (prince.action?.type !== ActionType.Spy) return []
  for (const player of game.players) {
    if (isThief(player) && player.meeples.includes(district) && player.spied.length < SPY_MAX) {
      moves.push(spyDistrictCardsMove(player.color, Math.min(prince.spy, SPY_MAX - player.spied.length)))
    }
  }
  return moves
}