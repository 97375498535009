import {getDistrictRules} from '../Brigands'
import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import PlayerColor from '../players/PlayerColor'
import {STOCK} from '../players/PlayerCommon'
import MoveType from './MoveType'

type SpendTokens = {
  type: MoveType.SpendTokens
  player: PlayerColor
  district: District
  tokens: number
}

export default SpendTokens

export function spendTokensMove(player: PlayerColor, district: District, tokens: number = 1): SpendTokens {
  return {type: MoveType.SpendTokens, player, district, tokens}
}

export function spendTokens(state: GameState | GameView, move: SpendTokens) {
  const player = state.players.find(player => player.color === move.player)!
  for (let i = 0; i < move.tokens; i++) {
    player.tokens.splice(player.tokens.indexOf(STOCK), 1)
  }
  getDistrictRules(state).onSpendTokens(move)
}

export function spendTokensInView(state: GameView, move: SpendTokens) {
  spendTokens(state, move)
  const player = state.players.find(player => player.color === move.player)!
  for (let i = 0; i < move.tokens; i++) {
    const reserveCount = player.tokensPlace.reduce((sum, token) => token.location === undefined ? sum + 1 : sum, 0)
    const token = player.tokensPlace.filter(token => token.location === STOCK).reduce((token, current) => current.position > token.position ? current : token)
    delete token.location
    token.position = reserveCount
  }
}