/** @jsxImportSource @emotion/react */
import District from '@gamepark/brigands/districts/District'
import ThiefView from '@gamepark/brigands/players/ThiefView'
import {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import HeaderButton from '../../HeaderButton'
import {districtsName} from '../DistrictHelpDialog'
import JailDialog from './JailDialog'

type Props = {
  player: ThiefView
}

export default function JailActionHeader({player}: Props) {
  const {t} = useTranslation()
  const [open, setOpen] = useState(true)
  return (
    <>
      <Trans defaults="district.choice" values={{district: districtsName[District.Jail](t)}} components={[
        <HeaderButton color={player.color} onClick={() => setOpen(true)}/>
      ]}/>
      <JailDialog open={open} close={() => setOpen(false)} player={player}/>
    </>
  )
}