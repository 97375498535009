import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import Move from '../moves/Move'
import {stealGoldMove} from '../moves/StealGold'
import {takeBackMeepleMove} from '../moves/TakeBackMeeple'
import {takeTokenMove} from '../moves/TakeToken'
import isPrince from '../players/IsPrince'
import isThief from '../players/IsThief'
import PlayerColor from '../players/PlayerColor'
import {MAX_TOKENS} from '../players/PlayerCommon'
import PrinceState from '../players/PrinceState'
import PrinceView from '../players/PrinceView'

export default function judge(state: GameState | GameView): Move[] {
  const moves: Move[] = []
  const prince = state.players.find(isPrince) as PrinceState | PrinceView
  if (prince.tokens.length < MAX_TOKENS) {
    moves.push(takeTokenMove(prince.color, District.Jail))
  }
  for (const player of state.players) {
    if (isThief(player)) {
      let goldLeft = player.gold
      for (let meeple = 0; meeple < player.meeples.length; meeple++) {
        if (player.meeples[meeple] === District.Jail) {
          const goldPaid = Math.min(goldLeft, prince.judge)
          if (goldPaid > 0) {
            moves.push(stealGoldMove(PlayerColor.White, goldPaid, District.Jail, player.color))
            goldLeft -= goldPaid
          }
          moves.push(takeBackMeepleMove(player.color, meeple))
        }
      }
    }
  }
  for (let meeple = 0; meeple < prince.meeples.length; meeple++) {
    if (prince.meeples[meeple] === District.Jail) {
      moves.push(takeBackMeepleMove(prince.color, meeple))
    }
  }
  return moves
}
