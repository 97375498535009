/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import {getCurrentDistrict} from '@gamepark/brigands/Brigands'
import GameView from '@gamepark/brigands/GameView'
import Phase from '@gamepark/brigands/Phase'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {usePlayerId, useTutorial} from '@gamepark/react-client'
import {Letterbox} from '@gamepark/react-components'
import {useMemo, useState} from 'react'
import City from './board/City'
import DiceAnimation from './board/DiceAnimation'
import WelcomePopUp from './board/WelcomePopUp'
import PlayerActionWheel, {getActionTargets} from './players/action-wheel/PlayerActionWheel'
import PrinceHand from './players/district-cards/PrinceHand'
import {PlayerDisplay} from './players/PlayerDisplay'
import {getPlayerPosition} from './players/PlayerPosition'
import BrigandsSounds from './sounds/BrigandsSounds'
import TutorialPopup from './tutorial/TutorialPopUp'
import {AudioLoader} from './utils/AudioLoader'
import SpyRulesDialog from './board/SpyRulesDialog'
import {GoldAnimation} from './players/GoldAnimation'

type Props = {
  game: GameView
  audioLoader: AudioLoader
}

export default function GameDisplay({game, audioLoader}: Props) {

  const tutorial = useTutorial()

  const playerId = usePlayerId<PlayerColor>()
  const players = useMemo(() => getPlayersStartingWith(game, playerId), [game, playerId])
  const player = players.find(player => player.color === playerId)

  const currentDistrict = game.phase === Phase.Solving ? getCurrentDistrict(game) : undefined

  const [spyRulesOpen, setSpyRulesOpen] = useState(false)

  return (
    <>
      <Letterbox css={letterBoxStyle} top={0}>

        <City city={game.city} player={player} currentDistrict={currentDistrict}
              selectedMeeple={game.selectedMeeple} selectedToken={game.selectedToken} deck={game.deck} dayCards={game.dayCards}/>

        {game.players.map(player =>
          <PlayerDisplay key={player.color} player={player} position={getPlayerPosition(game.players, player.color, playerId)}
                         me={player.color === playerId} city={game.city} phase={game.phase} openSpyRules={() => setSpyRulesOpen(true)}
                         currentDistrict={currentDistrict} selectedMeeple={game.selectedMeeple} selectedToken={game.selectedToken}
                         gameOver={game.phase === Phase.NewDay && game.deck === 0}/>
        )}

        <GoldAnimation getPlayerPosition={(color, id) => getPlayerPosition(game.players, color, id)}/>

        <DiceAnimation/>

        <PrinceHand players={game.players} onCardClick={() => setSpyRulesOpen(true)}/>

        <SpyRulesDialog players={game.players} currentDistrict={currentDistrict} open={spyRulesOpen} close={() => setSpyRulesOpen(false)}/>

        {player && <PlayerActionWheel playerId={player.color} district={getCurrentDistrict(game)} targets={getActionTargets(game, player.color)}
                                      playerAction={player.action} tutorial={tutorial && game.deck === 5}/>}

        {tutorial && <TutorialPopup game={game} tutorial={tutorial}/>}

        {!tutorial && <WelcomePopUp player={player} game={game}/>}

        <BrigandsSounds audioLoader={audioLoader} phase={game.phase}/>

      </Letterbox>

    </>
  )
}

export const getPlayersStartingWith = (game: GameView, playerId?: PlayerColor) => {
  if (playerId) {
    const playerIndex = game.players.findIndex(player => player.color === playerId)
    return [...game.players.slice(playerIndex, game.players.length), ...game.players.slice(0, playerIndex)]
  } else {
    return game.players
  }
}

const fadeIn = keyframes`
  from, 50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const letterBoxStyle = css`
  animation: ${fadeIn} 3s ease-in forwards;
`