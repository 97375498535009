import actionWheelBlue from './action-wheels/blue-action-wheel.jpg'
import actionWheelGreen from './action-wheels/green-action-wheel.jpg'
import actionWheelPurple from './action-wheels/purple-action-wheel.jpg'
import actionWheelRed from './action-wheels/red-action-wheel.jpg'
import actionWheelWhite from './action-wheels/white-action-wheel.jpg'
import actionWheelYellow from './action-wheels/yellow-action-wheel.jpg'
import coin1 from './Coin1.png'
import coin2 from './Coin2.png'
import coin5 from './Coin5.png'
import dayCardBack from './day-cards/day-card-back.jpg'
import dayCardCityHall from './day-cards/day-card-city-hall.jpg'
import dayCardConvoy from './day-cards/day-card-convoy.jpg'
import dayCardHarbor from './day-cards/day-card-harbor.jpg'
import dayCardMarket from './day-cards/day-card-market.jpg'
import dayCardPalace from './day-cards/day-card-palace.jpg'
import dayCardTavern from './day-cards/day-card-tavern.jpg'
import dayCardTreasure from './day-cards/day-card-treasure.jpg'
import harborSell from './district-actions/harbor-sell.jpg'
import harborTake2 from './district-actions/harbor-take-2.jpg'
import harborTake3 from './district-actions/harbor-take-3.jpg'
import jailFree from './district-actions/jail-free.jpg'
import jailRemain from './district-actions/jail-remain.jpg'
import tavernRoll1 from './district-actions/tavern-roll-1.jpg'
import tavernRoll3 from './district-actions/tavern-roll-3.jpg'
import cardCityHall from './district-cards/card-city-hall.jpg'
import cardConvoy from './district-cards/card-convoy.jpg'
import cardHarbor from './district-cards/card-harbor.jpg'
import cardMarket from './district-cards/card-market.jpg'
import cardPalace from './district-cards/card-palace.jpg'
import cardTavern from './district-cards/card-tavern.jpg'
import cardTreasure from './district-cards/card-treasure.jpg'
import iconJail from './district-icons/icon-jail.png'
import iconCityHall from './district-icons/icon-city-hall.png'
import iconConvoy from './district-icons/icon-convoy.png'
import iconHarbor from './district-icons/icon-harbor.png'
import iconMarket from './district-icons/icon-market.png'
import iconPalace from './district-icons/icon-palace.png'
import iconTavern from './district-icons/icon-tavern.png'
import iconTreasure from './district-icons/icon-treasure.png'
import districtCardBack from './district-cards/district-back.jpg'
import sealBlue from './district-cards/seal-blue.png'
import sealGreen from './district-cards/seal-green.png'
import sealPurple from './district-cards/seal-purple.png'
import sealRed from './district-cards/seal-red.png'
import sealYellow from './district-cards/seal-yellow.png'
import cityHall from './districts/city-hall.png'
import convoy from './districts/convoy.png'
import districtStart from './districts/district-start.png'
import harbour from './districts/harbour.png'
import jail from './districts/jail.png'
import market from './districts/market.png'
import palace from './districts/palace.png'
import tavern from './districts/tavern.png'
import treasure from './districts/treasure.png'
import meepleBlue from './meeples/blue-meeple.png'
import meepleGreen from './meeples/green-meeple.png'
import meeplePurple from './meeples/purple-meeple.png'
import meepleRed from './meeples/red-meeple.png'
import meepleWhite from './meeples/white-meeple.png'
import meepleYellow from './meeples/yellow-meeple.png'
import princeBoard from './prince-board.png'
import actionTokenBlue from './tokens/action-token-blue.jpg'
import actionTokenGreen from './tokens/action-token-green.jpg'
import actionTokenPurple from './tokens/action-token-purple.jpg'
import actionTokenRed from './tokens/action-token-red.jpg'
import actionTokenWhite from './tokens/action-token-white.jpg'
import actionTokenYellow from './tokens/action-token-yellow.jpg'
import skillMarker from './tokens/skill-marker.png'

const Images = {
  districtCardBack, sealBlue, sealRed, sealGreen, sealPurple, sealYellow,
  cardCityHall, cardHarbor, cardMarket, cardPalace, cardTavern, cardTreasure, cardConvoy,
  iconJail, iconCityHall, iconHarbor, iconMarket, iconPalace, iconTavern, iconTreasure, iconConvoy,
  districtStart, cityHall, convoy, harbour, jail, market, palace, tavern, treasure,
  princeBoard, skillMarker,
  coin1, coin2, coin5,
  meepleWhite, meepleGreen, meepleBlue, meeplePurple, meepleRed, meepleYellow,
  actionTokenWhite, actionTokenBlue, actionTokenGreen, actionTokenPurple, actionTokenRed, actionTokenYellow,
  actionWheelWhite, actionWheelBlue, actionWheelGreen, actionWheelPurple, actionWheelRed, actionWheelYellow,
  dayCardBack, dayCardCityHall, dayCardHarbor, dayCardMarket, dayCardPalace, dayCardTavern, dayCardConvoy, dayCardTreasure,
  tavernRoll1, tavernRoll3, harborSell, harborTake2, harborTake3, jailFree, jailRemain
}

export default Images