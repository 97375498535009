import Move from '../moves/Move'
import StealGold, {stealGoldMove} from '../moves/StealGold'
import {throwDicesMove, ThrowDicesRandomized} from '../moves/ThrowDices'
import isThief from '../players/IsThief'
import PlayerState from '../players/PlayerState'
import District from './District'
import {DistrictRules} from './DistrictRules'

export const TAVERN_COST = 6

export default class Tavern extends DistrictRules {
  district = District.Tavern

  getAutomaticMoves(): Move[] {
    if (!this.hasDayCard()) return []
    const moves: Move[] = []
    for (const player of this.state.players) {
      if (player.meeples.includes(this.district)) {
        moves.push(throwDicesMove(2, this.district, player.color))
      }
    }
    return moves
  }

  getLegalMoves(player: PlayerState): Move[] {
    const moves: Move[] = [throwDicesMove(1, this.district, player.color)]
    if (isThief(player) && player.gold >= TAVERN_COST) {
      moves.push(stealGoldMove(player.color, -TAVERN_COST, this.district))
    }
    return moves
  }

  onThrowDices(move: ThrowDicesRandomized) {
    if (move.player) {
      this.state.nextMoves.push(stealGoldMove(move.player, move.result.reduce((sum, dice) => sum + dice), this.district))
      this.takeBackMeeple(move.player)
    }
  }

  onStealGold(move: StealGold) {
    if (move.gold === -TAVERN_COST) {
      this.state.nextMoves.push(throwDicesMove(3, this.district, move.player))
    }
  }
}
