/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import District from '@gamepark/brigands/districts/District'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Dialog, DialogProps} from '@gamepark/react-components'
import {useTranslation} from 'react-i18next'
import Button from '../../utils/Button'
import {dialogCloseIcon, dialogCss} from '../../utils/styles'
import {chooseActionMove} from '@gamepark/brigands/moves/ChooseAction'
import ActionType from '@gamepark/brigands/tokens/ActionType'

type Props = {
  district: District
  close: () => void
} & DialogProps

export default function PassActionDialog({district, close, ...props}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()!
  const play = usePlay()
  return (
    <Dialog css={dialogCss} {...props} onBackdropClick={close}>
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={close}/>
      <h2>{t('action.pass')}</h2>
      <p>{t('action.pass.rules')}</p>
      <div css={flexContainer}>
        <Button onClick={close}>
          {t('Cancel')}
        </Button>
        <Button playerColor={playerId} onClick={() => {
          play(chooseActionMove(playerId, {type: ActionType.Pass}, district))
          close()
        }}>
          {t('pass')}
        </Button>
      </div>
    </Dialog>
  )
}

const flexContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  button {
    font-size: 3.5em;
    margin: 0 1em;
  }
`
