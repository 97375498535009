/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {getPlayerName} from '@gamepark/brigands/BrigandsOptions'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import PlayerView from '@gamepark/brigands/players/PlayerView'
import {Avatar, GamePoints, PlayerTimer, usePlayer} from '@gamepark/react-client'
import {SpeechBubbleDirection} from '@gamepark/react-client/dist/Avatar'
import {HTMLAttributes} from 'react'
import {useTranslation} from 'react-i18next'
import {playerPanelHeight, playerPanelWidth, playerPanelX, playerPanelY} from '../utils/styles'
import PlayerPosition from './PlayerPosition'

type Props = {
  player: PlayerView
  position: PlayerPosition
  gameOver: boolean
} & HTMLAttributes<HTMLDivElement>

export default function PlayerPanel({player, position, gameOver, ...props}: Props) {
  const playerInfo = usePlayer(player.color)
  const {t} = useTranslation()
  return (
    <div css={[panelPlayerStyle(getPlayerColor(player.color)), positionCss(position)]} {...props}>
      <Avatar css={avatarStyle} playerId={player.color} speechBubbleProps={{direction: SpeechBubbleDirection.BOTTOM_LEFT}}/>
      <h2 css={nameStyle}>{playerInfo?.name === undefined ? getPlayerName(player.color, t) : playerInfo?.name}</h2>
      {!gameOver && <PlayerTimer playerId={player.color} css={timerStyle}/>}
      <GamePoints playerId={player.color} css={gamePointCss}/>
    </div>
  )
}

const positionCss = (playerPosition: PlayerPosition) => css`
  position: absolute;
  left: ${playerPanelX(playerPosition)}em;
  top: ${playerPanelY(playerPosition)}em;
`

const nameStyle = css`
  position: absolute;
  top: 0.3em;
  left: 3.1em;
  max-width: 7.3em;
  font-size: 2.4em;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: 'Mulish', sans-serif;
`

const timerStyle = css`
  position: absolute;
  top: 1.6em;
  left: 3.1em;
  font-size: 2.5em;
`

const panelPlayerStyle = (color: string) => css`
  border: 0.5em solid ${color};
  border-radius: 2em;
  width: ${playerPanelWidth}em;
  height: ${playerPanelHeight}em;
`

export function getPlayerColor(color: PlayerColor): string {
  switch (color) {
    case PlayerColor.Blue :
      return '#0090ff'
    case PlayerColor.Red :
      return '#fe0000'
    case PlayerColor.Green :
      return '#49cf00'
    case PlayerColor.Purple :
      return '#a100fe'
    case PlayerColor.Yellow :
      return '#fea500'
    default :
      return '#FFFFFF'
  }
}

const avatarStyle = css`
  position: absolute;
  top: 1em;
  left: 1em;
  border-radius: 100%;
  height: 6em;
  width: 6em;
  color: black;
`

const gamePointCss = css`
  position: absolute;
  top: 1.6em;
  left: 3.1em;
  font-size: 2.5em;
`
