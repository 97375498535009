import Move from '../moves/Move'
import SpendTokens, {spendTokensMove} from '../moves/SpendTokens'
import {stealGoldMove} from '../moves/StealGold'
import TakeToken, {takeTokenMove} from '../moves/TakeToken'
import {MAX_TOKENS, STOCK} from '../players/PlayerCommon'
import PlayerState from '../players/PlayerState'
import District from './District'
import {DistrictRules} from './DistrictRules'

export default class Harbor extends DistrictRules {
  district = District.Harbor

  getLegalMoves(player: PlayerState): Move[] {
    const moves: Move[] = []
    if (player.tokens.length < MAX_TOKENS) {
      moves.push(takeTokenMove(player.color, this.district))
    }
    const stock = player.tokens.reduce<number>((sum, location) => location === STOCK ? sum + 1 : sum, 0)
    for (let tokens = 1; tokens <= stock; tokens++) {
      moves.push(spendTokensMove(player.color, this.district, tokens))
    }
    return moves
  }

  onTakeToken(move: TakeToken) {
    if (this.state.nextMoves.length > 0) return
    const player = this.state.players.find(player => player.color === move.player)!
    if (player.tokens.length < MAX_TOKENS) {
      this.state.nextMoves.push(takeTokenMove(player.color, this.district))
      if (this.hasDayCard() && player.tokens.length + 1 < MAX_TOKENS) {
        this.state.nextMoves.push(takeTokenMove(player.color, this.district))
      }
    }
    this.takeBackMeeple(move.player)
  }

  onSpendTokens(move: SpendTokens) {
    this.state.nextMoves.push(stealGoldMove(move.player, move.tokens * 3, this.district))
    this.takeBackMeeple(move.player)
  }
}