/** @jsxImportSource @emotion/react */
import District from '@gamepark/brigands/districts/District'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {STOCK, TokenLocation} from '@gamepark/brigands/players/PlayerCommon'
import TokenView from '@gamepark/brigands/tokens/TokenView'
import {useAnimation, usePlay} from '@gamepark/react-client'
import {selectTokenMove} from '../localMoves/SelectToken'
import {noPointerEvents, positionAbsolute} from '../utils/styles'
import {isTokenAnimation, tokenIndex, tokenTransition, tokenTranslate} from './PlayerActionTokens'
import PlayerPosition from './PlayerPosition'
import {DraggableActionToken} from './tokens/DraggableActionToken'
import usePlayPlaceToken from './tokens/usePlayPlaceToken'

type Props = {
  player: PlayerColor
  playerPosition: PlayerPosition
  tokens: TokenView[]
  city: District[]
  selectedToken?: TokenLocation
  canPlaceToken?: boolean
}

export function MyActionTokens({player, playerPosition, tokens, city, selectedToken, canPlaceToken}: Props) {
  const play = usePlay()
  const playPlaceToken = usePlayPlaceToken()
  const lastInStock = Math.max(0, ...tokens.filter(place => place.location === STOCK).map(place => place.position))
  const animation = useAnimation(animation => isTokenAnimation(animation) && animation.move.player === player)
  return <>
    {tokens.map(token => {
        const canDrag = canPlaceToken && ((token.location === STOCK && lastInStock === token.position) || !!token.location)
        return <DraggableActionToken key={token.id} playerColor={player} selected={canDrag && selectedToken === token.location}
                                     canDrag={canDrag} preTransform={tokenTranslate(player, playerPosition, token, city)}
                                     onClick={() => canDrag && play(selectTokenMove(selectedToken === token.location ? undefined : token.location), {local: true})}
                                     drop={({district}) => playPlaceToken(district, token.location)}
                                     css={[
                                       positionAbsolute, tokenIndex(token.position),
                                       animation && tokenTransition(animation.duration),
                                       !!token.location && !canPlaceToken && noPointerEvents
                                     ]}/>
      }
    )}
  </>
}
