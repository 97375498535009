enum MoveType {
  TakeToken = 1,
  DrawDayCard,
  MoveOnNextPhase,
  PlaceMeeple,
  PlaceToken,
  Pass,
  ChooseAction,
  TakeBackMeeple,
  StealGold,
  DiscardToken,
  SpendTokens,
  ThrowDices,
  DiscardDayCard,
  UpgradeSkill,
  SpyDistrictCards
}

export default MoveType