/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {canPlaceMeepleInDistrict, canPlaceTokenInDistrict} from '@gamepark/brigands/Brigands'
import District from '@gamepark/brigands/districts/District'
import {TokenLocation} from '@gamepark/brigands/players/PlayerCommon'
import PlayerView from '@gamepark/brigands/players/PlayerView'
import {HTMLAttributes, useState} from 'react'
import {useDrop} from 'react-dnd'
import Images from '../images/Images'
import {isMeepleItem, MEEPLE, MeepleItem} from '../players/meeples/DraggableMeeple'
import usePlayPlaceMeeple from '../players/meeples/usePlayPlaceMeeple'
import {ACTION_TOKEN, ActionTokenItem} from '../players/tokens/DraggableActionToken'
import usePlayPlaceToken from '../players/tokens/usePlayPlaceToken'
import {cityCenterLeft, cityCenterTop, cursorHelp, cursorPointer, districtImageRatio, districtWidth, shineEffect} from '../utils/styles'
import DistrictHelpDialog from './DistrictHelpDialog'
import isThief from '@gamepark/brigands/players/IsThief'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBan} from '@fortawesome/free-solid-svg-icons/faBan'

type Props = {
  district: District
  selectedMeeple?: number
  selectedToken?: TokenLocation
  player?: PlayerView
  darken?: boolean
} & HTMLAttributes<HTMLDivElement>

export default function DistrictTile({district, selectedMeeple, selectedToken, player, darken, ...props}: Props) {

  const canPlaceMeeple = !!player && canPlaceMeepleInDistrict(player, district)
  const canPlaceToken = !!player && canPlaceTokenInDistrict(player, district)
  const playPlaceMeeple = usePlayPlaceMeeple()
  const playPlaceToken = usePlayPlaceToken()
  const [helpOpen, setHelpOpen] = useState(false)

  const [{dragging, canDrop, isOver}, dropRef] = useDrop({
    accept: [MEEPLE, ACTION_TOKEN],
    canDrop: (item: MeepleItem | ActionTokenItem) => isMeepleItem(item) ? canPlaceMeeple && item.location !== district : canPlaceToken,
    collect: monitor => ({
      dragging: monitor.getItemType() === MEEPLE || monitor.getItemType() === ACTION_TOKEN,
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver()
    }),
    drop: () => ({district})
  })
  const canPlaceSelectedMeeple = selectedMeeple !== undefined && canPlaceMeeple && player.meeples[selectedMeeple] !== district
  const canPlaceSelectedToken = selectedToken !== undefined && canPlaceToken && selectedToken !== district
  const highlight = canDrop || canPlaceSelectedMeeple || canPlaceSelectedToken
  const spied = player && isThief(player) && player.spied.includes(district)
  const onClick = canPlaceSelectedMeeple ? () => playPlaceMeeple(selectedMeeple, district)
    : canPlaceSelectedToken ? () => playPlaceToken(district, selectedToken)
      : () => setHelpOpen(true)
  return (
    <>
      <div ref={dropRef}
           css={[
             districtCss, canPlaceSelectedMeeple || canPlaceSelectedToken ? cursorPointer : cursorHelp,
             district === District.Jail ? jailCss : suburbCss, districtBackground(district),
             isOver ? strongHighlightCss : highlight && shineEffect,
             (darken || (dragging && !canDrop) || (selectedMeeple !== undefined && !canPlaceMeeple) || (selectedToken !== undefined && !canPlaceToken)) && darkenCss
           ]}
           onClick={onClick} {...props}>
        {spied && (dragging || selectedMeeple !== undefined) && <FontAwesomeIcon icon={faBan} css={spiedIcon}/>}
      </div>
      <DistrictHelpDialog district={district} spied={spied}
                          open={helpOpen} onBackdropClick={() => setHelpOpen(false)} close={() => setHelpOpen(false)}/>
    </>
  )
}

const strongHighlightCss = css`
  filter: brightness(130%);
`

const darkenCss = css`
  filter: brightness(50%);
`

const jailSize = 31.5

const districtCss = css`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;

  &:hover {
    filter: brightness(110%);
  }
`

const jailCss = css`
  position: absolute;
  left: ${cityCenterLeft - jailSize / 2}em;
  top: ${cityCenterTop - jailSize / 2}em;
  width: ${jailSize}em;
  height: ${jailSize}em;
  border-radius: 50%;
`

const suburbCss = css`
  width: ${districtWidth}em;
  height: ${districtWidth * districtImageRatio}em;
  clip-path: polygon(30.6% 0, 69.4% 0, 100% 73.2%, 82.9% 78.7%, 82.9% 100%, 17.6% 100%, 17.7% 78.9%, 0% 73.3%);
`

const districtBackground = (district: District) => css`
  background-image: url(${districtImage[district]});
`

export const districtImage: Record<District, string> = {
  [District.Jail]: Images.jail,
  [District.Tavern]: Images.tavern,
  [District.Market]: Images.market,
  [District.Harbor]: Images.harbour,
  [District.CityHall]: Images.cityHall,
  [District.Treasure]: Images.treasure,
  [District.Palace]: Images.palace,
  [District.Convoy]: Images.convoy
}

export const districtIcon: Record<District, string> = {
  [District.Jail]: Images.iconJail,
  [District.Tavern]: Images.iconTavern,
  [District.Market]: Images.iconMarket,
  [District.Harbor]: Images.iconHarbor,
  [District.CityHall]: Images.iconCityHall,
  [District.Treasure]: Images.iconTreasure,
  [District.Palace]: Images.iconPalace,
  [District.Convoy]: Images.iconConvoy
}

export const districtAngle = (districtIndex: number) => (districtIndex - 1) * 45

const spiedIcon = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5em;
  color: red;
`
