/** @jsxImportSource @emotion/react */
import District from '@gamepark/brigands/districts/District'
import ThiefView from '@gamepark/brigands/players/ThiefView'
import {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import HeaderButton from '../../HeaderButton'
import {districtsName} from '../DistrictHelpDialog'
import HarborDialog from './HarborDialog'

type Props = {
  player: ThiefView
  dayCard: boolean
}

export default function HarborActionHeader({player, dayCard}: Props) {
  const {t} = useTranslation()
  const [open, setOpen] = useState(true)
  return (
    <>
      <Trans defaults="district.choice" values={{district: districtsName[District.Harbor](t)}} components={[
        <HeaderButton color={player.color} onClick={() => setOpen(true)}/>
      ]}/>
      <HarborDialog open={open} close={() => setOpen(false)} dayCard={dayCard}
                    tokens={player.tokens}/>
    </>
  )
}