import {setupPlayers} from '@gamepark/brigands/Brigands'
import District from '@gamepark/brigands/districts/District'
import GameState from '@gamepark/brigands/GameState'
import Move from '@gamepark/brigands/moves/Move'
import Phase from '@gamepark/brigands/Phase'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {AvatarProps, ClotheType, EyebrowType, GraphicType, MouthType, TopType, TutorialDescription} from '@gamepark/react-client'
import {placeMeepleMove} from '@gamepark/brigands/moves/PlaceMeeple'
import {placeTokenMove} from '@gamepark/brigands/moves/PlaceToken'
import {passMove} from '@gamepark/brigands/moves/Pass'
import {chooseActionMove} from '@gamepark/brigands/moves/ChooseAction'
import ActionType from '@gamepark/brigands/tokens/ActionType'
import {throwDicesMove} from '@gamepark/brigands/moves/ThrowDices'
import {stealGoldMove} from '@gamepark/brigands/moves/StealGold'
import {takeTokenMove} from '@gamepark/brigands/moves/TakeToken'
import ClotheColorName from '@gamepark/avataaars/dist/avatar/clothes/ClotheColorName'
import SkinColor from '@gamepark/avataaars/dist/avatar/SkinColor'

const city: District[] = [
  District.CityHall,
  District.Palace,
  District.Tavern,
  District.Treasure,
  District.Harbor,
  District.Market,
  District.Convoy
]
const playerColors = [PlayerColor.Green, PlayerColor.White, PlayerColor.Red]
const deck: number[] = [District.Market, District.Tavern, District.Harbor, District.Palace, District.CityHall, District.Convoy]

const BrigandsTutorial: TutorialDescription<GameState, Move, PlayerColor> = {

  setupTutorial: () => [{
    players: setupPlayers(playerColors.map(color => ({id: color}))),
    city,
    phase: Phase.NewDay,
    deck,
    dayCards: [],
    nextMoves: [],
    tutorial: true
  }, playerColors],

  expectedMoves: () => [
    placeMeepleMove(PlayerColor.Red, 0, District.CityHall),
    placeMeepleMove(PlayerColor.Red, 1, District.Palace),
    placeMeepleMove(PlayerColor.Red, 2, District.Tavern),
    placeTokenMove(PlayerColor.Red, District.Tavern),
    passMove(PlayerColor.Red),
    placeMeepleMove(PlayerColor.White, 0, District.Palace),
    placeMeepleMove(PlayerColor.White, 1, District.Market),
    placeMeepleMove(PlayerColor.White, 2, District.Harbor),
    passMove(PlayerColor.White),
    [0, 1, 2].map(meeple => placeMeepleMove(PlayerColor.Green, meeple, District.CityHall)),
    [0, 1, 2].map(meeple => placeMeepleMove(PlayerColor.Green, meeple, District.Tavern)),
    [0, 1, 2].map(meeple => placeMeepleMove(PlayerColor.Green, meeple, District.Harbor)),
    placeTokenMove(PlayerColor.Green, District.Tavern),
    passMove(PlayerColor.Green),
    chooseActionMove(PlayerColor.Red, {type: ActionType.Move}, District.Tavern),
    chooseActionMove(PlayerColor.Green, {type: ActionType.Steal, target: PlayerColor.Red}, District.Tavern),
    [throwDicesMove(1, District.Tavern, PlayerColor.Green), stealGoldMove(PlayerColor.Green, -6, District.Tavern)],
    takeTokenMove(PlayerColor.Green, District.Jail)
  ],

  avatars(playerId: PlayerColor): AvatarProps {
    if (playerId === PlayerColor.White) {
      return {
        circle: true,
        topType: TopType.PrinceCut,
        hairColor: '#E18118',
        clotheType: ClotheType.CollarSweater,
        clotheColor: ClotheColorName.Blue03,
        skinColor: SkinColor.Pale
      }
    } else {
      return {
        circle: true,
        topType: TopType.Eyepatch,
        clotheType: ClotheType.GraphicShirt,
        clotheColor: ClotheColorName.Red,
        graphicType: GraphicType.Skull,
        eyebrowType: EyebrowType.AngryNatural,
        mouthType: MouthType.Smile,
        skinColor: SkinColor.Tanned
      }
    }
  }
}

export default BrigandsTutorial