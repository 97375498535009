import {countPlayerMeeples} from '@gamepark/brigands/Brigands'
import District from '@gamepark/brigands/districts/District'
import GameView from '@gamepark/brigands/GameView'
import MoveType from '@gamepark/brigands/moves/MoveType'
import {placeMeepleMove} from '@gamepark/brigands/moves/PlaceMeeple'
import {useGame, usePlay, usePlayerId, useUndo} from '@gamepark/react-client'

export default function usePlayPlaceMeeple() {
  const play = usePlay()
  const [undo] = useUndo()
  const game = useGame<GameView>()
  const playerId = usePlayerId()
  const player = game?.players.find(player => player.color === playerId)
  return (meeple: number, district: District) => {
    if (!player) return
    const currentLocation = player.meeples[meeple]
    if (currentLocation) {
      if (player.tokens.includes(currentLocation) && countPlayerMeeples(player, currentLocation) === 1) {
        undo(move => move.type === MoveType.PlaceToken && move.district === currentLocation)
      }
      undo(pastMove => pastMove.type === MoveType.PlaceMeeple && pastMove.meeple === meeple)
    }
    play(placeMeepleMove(player.color, meeple, district))
  }
}