import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import Move from '../moves/Move'
import {placeMeepleMove} from '../moves/PlaceMeeple'
import PlayerColor from '../players/PlayerColor'
import {PLAYER_MEEPLES} from '../players/PlayerState'
import ActionType from './ActionType'

export default function moveToNextDistrict(game: GameState | GameView, district: District): Move[] {
  const moves: Move[] = []
  for (const player of game.players) {
    if (player.action?.type === ActionType.Move) {
      const nextDistrict = game.city[game.city.indexOf(district) + 1] ?? District.Jail
      if (player.color === PlayerColor.White) {
        moves.push(placeMeepleMove(player.color, player.meeples.indexOf(district), nextDistrict))
      } else {
        for (let meeple = 0; meeple < PLAYER_MEEPLES; meeple++) {
          if (player.meeples[meeple] === district) {
            moves.push(placeMeepleMove(player.color, meeple, nextDistrict))
          }
        }
      }
    }
  }
  return moves
}