import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import PlayerColor from '../players/PlayerColor'
import {STOCK} from '../players/PlayerCommon'
import MoveType from './MoveType'

type TakeBackMeeple = {
  type: MoveType.TakeBackMeeple
  player: PlayerColor
  meeple: number
}

export default TakeBackMeeple

export function takeBackMeepleMove(player: PlayerColor, meeple: number): TakeBackMeeple {
  return {type: MoveType.TakeBackMeeple, player, meeple}
}

export function takeBackAllDistrictMeeplesMoves(state: GameState | GameView, district: District) {
  const moves: TakeBackMeeple[] = []
  for (const player of state.players) {
    for (let meeple = 0; meeple < player.meeples.length; meeple++) {
      if (player.meeples[meeple] === district) {
        moves.push(takeBackMeepleMove(player.color, meeple))
      }
    }
  }
  return moves
}

export function takeBackMeeple(state: GameState | GameView, move: TakeBackMeeple) {
  const player = state.players.find(p => p.color === move.player)!
  if (move.meeple === 4) { // Extra patrol in 2 players games is remove
    player.meeples.pop()
  } else {
    player.meeples[move.meeple] = STOCK
  }
}
