import {css, keyframes} from '@emotion/react'
import PlayerPosition from '../players/PlayerPosition'

export const screenRatio = 16 / 9
export const headerHeight = 7
export const playerPanelWidth = 26.5
export const playerPanelHeight = 26.5
export const playerPanelXMin = 95
export const districtWidth = 31
export const districtImageRatio = 1653 / 1638

export const playerPanelX = (position: PlayerPosition) => position % 3 * (playerPanelWidth + 1) + playerPanelXMin
export const playerPanelY = (position: PlayerPosition) => position < 3 ? headerHeight + 1 : headerHeight + 2 + playerPanelHeight

export const jailSize = 31.5
export const cityCenterTop = 53.5
export const cityCenterLeft = 47.5

export const slideKeyframes = keyframes`
  0%, 30% {
    transform: translate(-33%, 33%);
  }
  70%, 100% {
    transform: translate(33%, -33%);
  }
`

export const shineEffect = css`
  overflow: hidden;

  &:after {
    content: '';
    pointer-events: none;
    position: absolute;
    top: -100%;
    left: -100%;
    width: 300%;
    height: 300%;
    animation: ${slideKeyframes} 3s infinite;
    z-index: 1;
    background: linear-gradient(to top right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 33%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 67%,
    rgba(255, 255, 255, 0) 100%);
  }
`

export const positionAbsolute = css`
  position: absolute;
`

export const dialogCss = css`
  position: relative;
  background-color: beige;
  text-align: center;
  padding: 5em;
  color: black;
  border-radius: 3em;
  box-shadow: 0 0 0.5em black, 0 0 0.5em black, 0 0 0.5em black;
  max-width: 100em;

  & > h2 {
    position: relative;
    font-size: 5em;
    margin: 0 1em 0.5em;
    text-align: center;
  }

  & > p {
    position: relative;
    text-align: left;
    white-space: break-spaces;
    font-size: 3em;
    margin: 0.8em auto;
  }

  & > button {
    font-size: 3.5em;
  }
`

export const dialogCloseIcon = css`
  position: absolute;
  right: 0.8em;
  top: 0.6em;
  font-size: 4em;
  cursor: pointer;
`

export const noPointerEvents = css`
  pointer-events: none;
`

export const cursorPointer = css`
  cursor: pointer;
`

export const cursorHelp = css`
  cursor: help;
`