import Move from '@gamepark/brigands/moves/Move'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import GameState from '@gamepark/brigands/GameState'
import Brigands, {countMeeples, countPlayerMeeples, getCurrentDistrict, patrolInDistrict} from '@gamepark/brigands/Brigands'
import Phase from '@gamepark/brigands/Phase'
import MoveType from '@gamepark/brigands/moves/MoveType'
import District from '@gamepark/brigands/districts/District'
import ActionType from '@gamepark/brigands/tokens/ActionType'
import isPrince from '@gamepark/brigands/players/IsPrince'
import {Skill} from '@gamepark/brigands/moves/UpgradeSkill'
import isThief from '@gamepark/brigands/players/IsThief'
import {MAX_TOKENS, STOCK} from '@gamepark/brigands/players/PlayerCommon'

export default function tutorialAI(game: GameState, playerId: PlayerColor): Promise<Move[]> {
  const legalMoves = new Brigands(game).getLegalMoves(playerId)
  const acceptableMoves = legalMoves.filter(move => isAcceptable(move, game, playerId))
  const moves = acceptableMoves.length > 0 ? acceptableMoves : legalMoves
  return Promise.resolve([moves[Math.floor(Math.random() * moves.length)]])
}

function isAcceptable(move: Move, game: GameState, playerId: PlayerColor): boolean {
  const player = game.players.find(player => player.color === playerId)!
  if (game.phase === Phase.Planning) {
    if (playerId === PlayerColor.White) {
      if (move.type === MoveType.PlaceMeeple) {
        return !patrolInDistrict(game, move.district) && game.players.some(player => isThief(player) && !player.spied.includes(move.district))
      }
    } else {
      if (move.type === MoveType.PlaceMeeple && move.district === District.Palace) {
        return countPlayerMeeples(player, District.Palace) < 2
      }
    }
  } else if (game.phase === Phase.Solving) {
    const district = getCurrentDistrict(game)
    if (move.type === MoveType.ChooseAction) {
      if (move.action.type === ActionType.GoHome) {
        if (district === District.Jail) return false
        const otherPlayerHere = game.players.some(player => player.color !== playerId && countPlayerMeeples(player, district) > 0)
        return playerId !== PlayerColor.White ? otherPlayerHere : !otherPlayerHere
      } else if (move.action.type === ActionType.Move) {
        const nextDistrict = game.city[game.city.indexOf(district) + 1] ?? District.Jail
        if (nextDistrict === District.Jail) return false
        if (patrolInDistrict(game, nextDistrict)) return false
        if (playerId === PlayerColor.White) {
          return game.players.some(player => isThief(player) && (player.tokens.includes(district) || countPlayerMeeples(player, nextDistrict) > 0))
        } else {
          if (nextDistrict === District.Palace) {
            const palaceMeeples = countMeeples(game, nextDistrict)
            if (palaceMeeples > 2) return false
            const myMeeplesHere = countPlayerMeeples(player, district)
            const myMeeplesThere = countPlayerMeeples(player, nextDistrict)
            if (myMeeplesHere + myMeeplesThere > 2) return false
          } else if (nextDistrict === District.Convoy) {
            if (countPlayerMeeples(player, district) + countMeeples(game, nextDistrict) < 2) return false
          } else if (nextDistrict === District.Treasure) {
            if (countPlayerMeeples(player, nextDistrict) > 0) return false
          }
        }
      } else if (move.action.type === ActionType.Pass) {
        return !game.players.some(player => player.color !== playerId && countPlayerMeeples(player, district) > 0)
      } else if (move.action.type === ActionType.Push) {
        if (patrolInDistrict(game, district) && !game.players.some(player => isPrince(player) && player.tokens.includes(district))) return false
        const nextDistrict = game.city[game.city.indexOf(district) + 1] ?? District.Jail
        if (nextDistrict !== District.Palace && nextDistrict !== District.Treasure) return true
        if (patrolInDistrict(game, nextDistrict)) return true
        const myMeeples = countPlayerMeeples(player, nextDistrict)
        const otherMeeples = countMeeples(game, nextDistrict) - myMeeples
        if (myMeeples > 0 && otherMeeples === 0) return false
      }
    } else if (move.type === MoveType.UpgradeSkill) {
      const prince = game.players.find(isPrince)!
      if (move.skill === Skill.Spy && prince.judge < 3 && game.deck.length === 0) return false
    } else if (move.type === MoveType.TakeToken) {
      if (district === District.Jail && player.tokens.includes(STOCK)) return false
      if (district === District.Harbor) {
        if (game.deck.length === 0 && countPlayerMeeples(player, district) < 2) return false
      }
    } else if (move.type === MoveType.SpendTokens) {
      if (district === District.Harbor && game.deck.length === 0) {
        const stock = player.tokens.reduce<number>((sum, location) => location === STOCK ? sum + 1 : sum, 0)
        if (stock < MAX_TOKENS && countPlayerMeeples(player, district) > 1) return false
        return move.tokens === stock
      }
    }
  }
  return true
}

