/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import District from '@gamepark/brigands/districts/District'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {Picture} from '@gamepark/react-components'
import {HTMLAttributes} from 'react'
import Images from '../../images/Images'

type Props = {
  playerColor: PlayerColor
  district?: District
} & HTMLAttributes<HTMLDivElement>

export default function DistrictCard({playerColor, district, ...props}: Props) {
  return (
    <div css={[cardCss, !district && rotate]} {...props}>
      {district &&
      <div css={[sideCss, frontCss(district)]}>
        <Picture src={sealsImage[playerColor]}/>
      </div>
      }
      <div css={[sideCss, backCss, rotate]}>
        <Picture src={sealsImage[playerColor]}/>
      </div>
    </div>
  )
}

export const districtCardWidth = 10
export const districtCardRatio = 7 / 5
export const districtCardHeight = districtCardWidth * districtCardRatio
const sealRatio = 360 / 338

const cardCss = css`
  width: ${districtCardWidth}em;
  height: ${districtCardHeight}em;
  transform-style: preserve-3d;

  > div {
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
`

const frontCss = (district: District) => css`
  background-image: url(${cardsImage[district]});

  img {
    position: absolute;
    left: 5%;
    top: 3.5%;
    width: 15.5%;
    height: ${15.5 / districtCardRatio * sealRatio}%;
    filter: drop-shadow(0.1em 0.1em 0.2em black);
  }
`

const sideCss = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 1em;
  backface-visibility: hidden;
  box-shadow: 0 0 0.3em black, 0 0 0.3em black;
  background-size: cover;
`

const rotate = css`
  transform: rotateY(180deg);
`

const backCss = css`
  background-image: url(${Images.districtCardBack});

  img {
    position: absolute;
    left: 25%;
    top: 30%;
    width: 50%;
    height: ${50 / districtCardRatio * sealRatio}%;
  }
`

const cardsImage: Record<District, string> = {
  [District.Market]: Images.cardMarket,
  [District.CityHall]: Images.cardCityHall,
  [District.Harbor]: Images.cardHarbor,
  [District.Tavern]: Images.cardTavern,
  [District.Palace]: Images.cardPalace,
  [District.Convoy]: Images.cardConvoy,
  [District.Treasure]: Images.cardTreasure,
  [District.Jail]: ''
}

const sealsImage: Record<PlayerColor, string> = {
  [PlayerColor.Blue]: Images.sealBlue,
  [PlayerColor.Red]: Images.sealRed,
  [PlayerColor.Green]: Images.sealGreen,
  [PlayerColor.Purple]: Images.sealPurple,
  [PlayerColor.Yellow]: Images.sealYellow,
  [PlayerColor.White]: ''
}
