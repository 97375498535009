import {getDistrictRules} from '../Brigands'
import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import PlayerColor from '../players/PlayerColor'
import MoveType from './MoveType'

type ThrowDices = {
  type: MoveType.ThrowDices
  dices: number
  district: District
  player?: PlayerColor
}

export default ThrowDices

export type ThrowDicesRandomized = ThrowDices & {
  result: number[]
}

export function throwDicesMove(dices: number, district: District, player?: PlayerColor): ThrowDices {
  const move: ThrowDices = {type: MoveType.ThrowDices, dices, district}
  if (player) move.player = player
  return move
}

export function randomizeThrowDices(move: ThrowDices): ThrowDicesRandomized {
  return {...move, result: [...Array(move.dices)].map(() => Math.floor(Math.random() * 6) + 1)}
}

export function throwDices(state: GameState | GameView, move: ThrowDicesRandomized) {
  getDistrictRules(state).onThrowDices(move)
}
