/** @jsxImportSource @emotion/react */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getPlayerName} from '@gamepark/brigands/BrigandsOptions'
import GameView from '@gamepark/brigands/GameView'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import PrinceView from '@gamepark/brigands/players/PrinceView'
import ThiefView from '@gamepark/brigands/players/ThiefView'
import {usePlayer, usePlayerId} from '@gamepark/react-client'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import Button from '../utils/Button'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {dialogCloseIcon, dialogCss} from '../utils/styles'
import {Dialog} from '@gamepark/react-components'
import Phase from '@gamepark/brigands/Phase'

type Props = {
  player?: ThiefView | PrinceView
  game: GameView
}

export default function WelcomePopUp({player, game}: Props) {
  const {t} = useTranslation()
  const [open, setOpen] = useState(game.deck === 5 && game.phase === Phase.Planning)
  const playerInfo = usePlayer(player !== undefined ? player.color : undefined)
  const playerId = usePlayerId<PlayerColor>()
  return (
    <Dialog css={dialogCss} open={open} onBackdropClick={() => setOpen(false)}>
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={() => setOpen(false)}/>
      {playerId ?
        <>
          <h2>{t('welcome.player', {playerName: playerInfo?.name ?? getPlayerName(playerId, t)})}</h2>
          <p>
            {game.players.length === 2 ?
              t('duel.goal', {colorBrigand: getPlayerName(playerId, t)})
              : playerId === PlayerColor.White ? t('prince.goal')
                : t('brigand.goal', {colorBrigand: getPlayerName(playerId, t)})}
          </p>
          <p>{t('good.luck')}</p>
        </>
        :
        <>
          <h2>{t('welcome.spectator')}</h2>
          <p>{game.players.length === 2 ? t('spectator.duel.goal') : t('spectator.goal')}</p>
        </>
      }
      <Button playerColor={playerId} onClick={() => setOpen(false)}>{t('OK')}</Button>
    </Dialog>
  )
}
