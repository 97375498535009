/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import District from '@gamepark/brigands/districts/District'
import DiscardToken from '@gamepark/brigands/moves/DiscardToken'
import Move from '@gamepark/brigands/moves/Move'
import MoveType from '@gamepark/brigands/moves/MoveType'
import PlaceToken from '@gamepark/brigands/moves/PlaceToken'
import TakeToken from '@gamepark/brigands/moves/TakeToken'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {STOCK} from '@gamepark/brigands/players/PlayerCommon'
import TokenView from '@gamepark/brigands/tokens/TokenView'
import {Animation, useAnimation} from '@gamepark/react-client'
import {districtAngle} from '../board/DistrictTile'
import {cityCenterLeft, cityCenterTop, noPointerEvents, playerPanelX, playerPanelY, positionAbsolute} from '../utils/styles'
import PlayerPosition from './PlayerPosition'
import ActionToken, {actionTokenSize} from './tokens/ActionToken'
import SpendTokens from '@gamepark/brigands/moves/SpendTokens'

type Props = {
  player: PlayerColor
  playerPosition: PlayerPosition
  tokens: TokenView[]
  city: District[]
}

export function PlayerActionTokens({player, playerPosition, tokens, city}: Props) {
  const animation = useAnimation(animation => isTokenAnimation(animation) && animation.move.player === player)
  return <>
    {tokens.map(token =>
      <ActionToken key={token.id} playerColor={player}
                   css={[
                     positionAbsolute, tokenIndex(token.position),
                     animation && tokenTransition(animation.duration),
                     !!token.location && noPointerEvents,
                     transformCss(player, playerPosition, token, city)
                   ]}/>
    )}
  </>
}

export function isTokenAnimation(animation: Animation<Move>): animation is Animation<PlaceToken> | Animation<TakeToken> | Animation<DiscardToken> | Animation<SpendTokens> {
  return animation.move.type === MoveType.PlaceToken || animation.move.type === MoveType.TakeToken
    || animation.move.type === MoveType.DiscardToken || animation.move.type === MoveType.SpendTokens
}

const transformCss = (color: PlayerColor, playerPosition: PlayerPosition, token: TokenView, city: District[]) => css`
  transform: ${tokenTranslate(color, playerPosition, token, city)};
`

export const tokenTransition = (duration: number) => css`
  z-index: 50;
  transition: transform ${duration}s ease-in-out;
`

export function tokenTranslate(color: PlayerColor, playerPosition: PlayerPosition, token: TokenView, city: District[]) {
  if (token.location === undefined) {
    return reserveTranslate(color, token.position)
  } else if (token.location === STOCK) {
    return stockTranslate(color, playerPosition, token.position)
  } else {
    return districtTranslate(color, city.indexOf(token.location))
  }
}

function reserveTranslate(color: PlayerColor, position: number) {
  return `translate(${reserveX(color, position)}em, ${reserveY(color, position)}em)`
}

function stockTranslate(color: PlayerColor, playerPosition: PlayerPosition, position: number) {
  return `translate(${playerPanelX(playerPosition) + position + 2}em, ${playerPanelY(playerPosition) + (color === PlayerColor.White ? 16 : 15)}em)`
}

function districtTranslate(color: PlayerColor, districtIndex: number) {
  return `translate(${districtX(color, districtIndex)}em, ${districtY(color, districtIndex)}em)`
}

const stockRadius = 45

function reserveX(color: PlayerColor, position: number) {
  const angle = reserveAngle(color)
  return cityCenterLeft - Math.cos(angle) * stockRadius - actionTokenSize / 2 - position * 0.2
}

function reserveY(color: PlayerColor, position: number) {
  const angle = reserveAngle(color)
  return cityCenterTop - Math.sin(angle) * stockRadius - actionTokenSize / 2 - position * 0.2
}

function reserveAngle(color: PlayerColor) {
  return 45 * (color - 1.5) * Math.PI / 180
}

function districtX(color: PlayerColor, districtIndex: number) {
  const deltaX = districtIndex !== -1 ? Math.cos(tokenDistrictAngle(color, districtIndex)) * districtRadius[color] : 0
  return cityCenterLeft - actionTokenSize / 2 - deltaX
}

function districtY(color: PlayerColor, districtIndex: number) {
  const deltaY = districtIndex !== -1 ? Math.sin(tokenDistrictAngle(color, districtIndex)) * districtRadius[color] : 1
  return cityCenterTop - actionTokenSize / 2 - deltaY
}

function tokenDistrictAngle(color: PlayerColor, districtIndex: number) {
  return (districtAngle(districtIndex) + deltaAngle[color]) * Math.PI / 180
}

const deltaAngle: Record<PlayerColor, number> = {
  [PlayerColor.White]: 0,
  [PlayerColor.Blue]: -16,
  [PlayerColor.Red]: 18,
  [PlayerColor.Green]: 16,
  [PlayerColor.Purple]: -18,
  [PlayerColor.Yellow]: 0
}

const districtRadius: Record<PlayerColor, number> = {
  [PlayerColor.White]: 27,
  [PlayerColor.Blue]: 25,
  [PlayerColor.Red]: 33.5,
  [PlayerColor.Green]: 25,
  [PlayerColor.Purple]: 33.5,
  [PlayerColor.Yellow]: 36
}

export const tokenIndex = (position: number) => css`
  z-index: ${position};
`