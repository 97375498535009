import {getDistrictRules} from '../Brigands'
import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import Phase from '../Phase'
import PlayerColor from '../players/PlayerColor'
import {STOCK} from '../players/PlayerCommon'
import MoveType from './MoveType'

type TakeToken = {
  type: MoveType.TakeToken
  player: PlayerColor
  district?: District
}

export default TakeToken

export function takeTokenMove(player: PlayerColor, district?: District): TakeToken {
  const move: TakeToken = {type: MoveType.TakeToken, player}
  if (district) move.district = district
  return move
}

export function takeToken(state: GameState | GameView, move: TakeToken) {
  const player = state.players.find(player => player.color === move.player)!
  player.tokens.push(STOCK)
  if (state.phase === Phase.Solving) {
    getDistrictRules(state).onTakeToken(move)
  }
}

export function takeTokenInView(game: GameView, move: TakeToken) {
  takeToken(game, move)
  const player = game.players.find(player => player.color === move.player)!
  const stockCount = player.tokensPlace.reduce((sum, token) => token.location === STOCK ? sum + 1 : sum, 0)
  const token = player.tokensPlace.filter(token => token.location === undefined).reduce((token, current) => current.position > token.position ? current : token)
  token.location = STOCK
  token.position = stockCount
}