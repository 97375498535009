/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import ActionType from '@gamepark/brigands/tokens/ActionType'
import TokenAction from '@gamepark/brigands/tokens/TokenAction'
import {Picture} from '@gamepark/react-components'
import {PictureAttributes} from '@gamepark/react-components/dist/Picture/Picture'
import Images from '../../images/Images'

type Props = {
  playerColor: PlayerColor
} & PictureAttributes

export default function ActionWheel({playerColor, ...props}: Props) {
  return <Picture src={wheelImage[playerColor]} css={wheelCss} {...props}/>
}

const wheelImage: Record<PlayerColor, string> = {
  [PlayerColor.White]: Images.actionWheelWhite,
  [PlayerColor.Blue]: Images.actionWheelBlue,
  [PlayerColor.Red]: Images.actionWheelRed,
  [PlayerColor.Green]: Images.actionWheelGreen,
  [PlayerColor.Purple]: Images.actionWheelPurple,
  [PlayerColor.Yellow]: Images.actionWheelYellow
}

export const wheelSize = 50

const wheelCss = css`
  width: ${wheelSize}em;
  height: ${wheelSize}em;
  border-radius: 50%;
  box-shadow: 0 0 0.5em black, 0 0 0.5em black, 0 0 0.5em black;
`

export const arcCss = (degree1: number, degree2: number) => css`
  clip-path: polygon(50% 50%, ${(1 + tanX(degree1)) * 50}% ${(1 + tanY(degree1)) * 50}%, ${(1 + tanX(degree2)) * 50}% ${(1 + tanY(degree2)) * 50}%);
`

const tanX = (angle: number) => {
  return tanY(angle - 90)
}

const tanY = (angle: number) => {
  angle = (angle % 360 + 360) % 360
  if (angle > 45 && angle <= 135) return -1
  else if (angle > 225 && angle <= 315) return 1
  else if (angle > 135 && angle <= 225) return Math.tan(angle * Math.PI / 180)
  else return -Math.tan(angle * Math.PI / 180)
}

export const thievesTargetOrder: Record<PlayerColor, PlayerColor[]> = {
  [PlayerColor.White]: [PlayerColor.Yellow, PlayerColor.Purple, PlayerColor.Green, PlayerColor.Red, PlayerColor.Blue],
  [PlayerColor.Blue]: [PlayerColor.Green, PlayerColor.Purple, PlayerColor.Yellow, PlayerColor.Red],
  [PlayerColor.Red]: [PlayerColor.Green, PlayerColor.Purple, PlayerColor.Yellow, PlayerColor.Blue],
  [PlayerColor.Green]: [PlayerColor.Blue, PlayerColor.Purple, PlayerColor.Yellow, PlayerColor.Red],
  [PlayerColor.Purple]: [PlayerColor.Green, PlayerColor.Blue, PlayerColor.Yellow, PlayerColor.Red],
  [PlayerColor.Yellow]: [PlayerColor.Green, PlayerColor.Purple, PlayerColor.Blue, PlayerColor.Red]
}

export function getActionAngle(player: PlayerColor, action: TokenAction): [number, number] {
  switch (action.type) {
    case ActionType.Spy:
      return [135, 225]
    case ActionType.GoHome:
      return [45, 135]
    case ActionType.Steal: {
      const index = thievesTargetOrder[player].indexOf(action.target!)
      const targets = player === PlayerColor.White ? 5 : 4
      return [45 - index * 90 / targets, 45 - (index + 1) * 90 / targets]
    }
    case ActionType.Move:
      return [-45, -135]
    case ActionType.Push: {
      const index = thievesTargetOrder[player].indexOf(action.target!)
      return [225 - index * 90 / 4, 225 - (index + 1) * 90 / 4]
    }
    default: return [0, 0]
  }
}
