/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import District from '@gamepark/brigands/districts/District'
import Move from '@gamepark/brigands/moves/Move'
import MoveType from '@gamepark/brigands/moves/MoveType'
import PlaceMeeple from '@gamepark/brigands/moves/PlaceMeeple'
import TakeBackMeeple from '@gamepark/brigands/moves/TakeBackMeeple'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {MeepleLocation, STOCK} from '@gamepark/brigands/players/PlayerCommon'
import {PLAYER_MEEPLES} from '@gamepark/brigands/players/PlayerState'
import {Animation, useAnimation} from '@gamepark/react-client'
import {districtAngle} from '../board/DistrictTile'
import {cityCenterLeft, cityCenterTop, jailSize, noPointerEvents, playerPanelX, playerPanelY, positionAbsolute} from '../utils/styles'
import Meeple, {meepleWidth, patrolHeight, thiefHeight} from './meeples/Meeple'
import PlayerPosition from './PlayerPosition'

type Props = {
  player: PlayerColor
  playerPosition: PlayerPosition
  meeples: MeepleLocation[]
  city: District[]
}

export function PlayerMeeples({player, playerPosition, meeples, city}: Props) {
  const animation = useAnimation(animation => isMeepleAnimation(animation) && animation.move.player === player)
  return <>
    {meeples.map((location, meeple) => {
      const meepleColor = meeple >= 3 ? PlayerColor.White : player
      return <Meeple key={meeple} meepleColor={meepleColor} css={[
        positionAbsolute,
        animation && transitionMeeple(animation.duration),
        !!location && noPointerEvents, transformCss(player, playerPosition, meeple, location, city)
      ]}/>
    })}
  </>
}

export function isMeepleAnimation(animation: Animation<Move>): animation is Animation<PlaceMeeple> | Animation<TakeBackMeeple> {
  return animation.move.type === MoveType.PlaceMeeple || animation.move.type === MoveType.TakeBackMeeple
}

const transformCss = (color: PlayerColor, playerPosition: PlayerPosition, meeple: number, location: MeepleLocation, city: District[]) => css`
  transform: ${translateMeeple(color, playerPosition, meeple, location, city)};
`

export const transitionMeeple = (duration: number) => css`
  z-index: 50;
  transition: transform ${duration}s ease-in-out;
`

export function translateMeeple(color: PlayerColor, playerPosition: PlayerPosition, meeple: number, location: MeepleLocation, city: District[]) {
  if (location === STOCK) {
    return stockTranslate(playerPosition, meeple)
  } else {
    return districtTranslate(color, meeple % 3, city.indexOf(location))
  }
}

function districtTranslate(color: PlayerColor, meeple: number, districtIndex: number) {
  return `translate(${districtX(color, meeple, districtIndex)}em, ${districtY(color, meeple, districtIndex)}em)`
}

function stockTranslate(position: PlayerPosition, meeple: number) {
  return `translate(${playerPanelX(position) + 2 + meeple * 5}em, ${playerPanelY(position) + (meeple >= PLAYER_MEEPLES ? 8.1 : 9)}em)`
}

function districtX(color: PlayerColor, meeple: number, districtIndex: number) {
  const radius = districtRadius(color, meeple, districtIndex)
  const angle = meepleDistrictAngle(color, meeple, districtIndex)
  return cityCenterLeft - Math.cos(angle) * radius - meepleWidth / 2
}

function districtY(color: PlayerColor, meeple: number, districtIndex: number) {
  const radius = districtRadius(color, meeple, districtIndex)
  const angle = meepleDistrictAngle(color, meeple, districtIndex)
  const meepleHeight = color === PlayerColor.White ? patrolHeight : thiefHeight
  return cityCenterTop - Math.sin(angle) * radius - meepleHeight / 2
}

function districtRadius(color: PlayerColor, meeple: number, districtIndex: number) {
  if (districtIndex === -1) { // Jail
    return color === PlayerColor.White ? 3 : meeple === 1 ? 7 : 11
  } else {
    return districtLine(color, meeple) * 3.7 + jailSize / 2 + 6.5
  }
}

function districtLine(color: PlayerColor, meeple: number) {
  switch (color) {
    case PlayerColor.White:
      return 2
    case PlayerColor.Green:
    case PlayerColor.Blue:
      return meeple > 0 ? 0 : 1
    case PlayerColor.Red:
    case PlayerColor.Purple:
      return meeple === 2 ? 2 : meeple === 1 ? 3 : 4
    default:
      return 3
  }
}

const deltaAngle: Record<PlayerColor, number[]> = {
  [PlayerColor.White]: [-7, 0, 7],
  [PlayerColor.Blue]: [-10, -15, -6],
  [PlayerColor.Red]: [18, 14, 17],
  [PlayerColor.Green]: [10, 6, 15],
  [PlayerColor.Purple]: [-18, -14, -17],
  [PlayerColor.Yellow]: [-6, 0, 6]
}

function meepleDistrictAngle(color: PlayerColor, meeple: number, districtIndex: number) {
  if (districtIndex === -1) { // Jail
    if (color === PlayerColor.Green || color === PlayerColor.Blue) meeple = 2 - meeple
    return (color === PlayerColor.White ? meeple * -90 : 240 + color * 50 + meeple * 10) * Math.PI / 180
  } else {
    return (districtAngle(districtIndex) + deltaAngle[color][meeple]) * Math.PI / 180
  }
}

