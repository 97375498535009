import GameState from '../GameState'
import GameView from '../GameView'
import PlayerColor from '../players/PlayerColor'
import PlayerView from '../players/PlayerView'
import MoveType from './MoveType'
import District from '../districts/District'

type DiscardToken = {
  type: MoveType.DiscardToken
  player: PlayerColor
}

export default DiscardToken

export function discardTokenMove(player: PlayerColor): DiscardToken {
  return {type: MoveType.DiscardToken, player}
}

export function discardToken(state: GameState | GameView, move: DiscardToken) {
  const player = state.players.find(player => player.color === move.player)!
  const district = state.city.find(district => player.tokens.includes(district)) ?? District.Jail
  player.tokens.splice(player.tokens.indexOf(district), 1)
  delete player.action
}

export function discardTokenInView(state: GameView, move: DiscardToken) {
  discardToken(state, move)
  const player = state.players.find(player => player.color === move.player)!
  const reserveCount = player.tokensPlace.reduce((sum, token) => token.location === undefined ? sum + 1 : sum, 0)
  const token = getTokenToDiscard(state, player)
  if (token) {
    delete token.location
    token.position = reserveCount
  } else {
    console.error('No token to discard in the city!', player)
  }
}

function getTokenToDiscard(state: GameView, player: PlayerView) {
  for (const district of state.city) {
    const token = player.tokensPlace.find(token => token.location === district)
    if (token) {
      return token
    }
  }
  return player.tokensPlace.find(token => token.location === District.Jail)
}
