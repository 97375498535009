import District from '../districts/District'
import PlayerColor from './PlayerColor'
import PlayerCommon from './PlayerCommon'

export const GOLD_TO_WIN = 50

type ThiefState = PlayerCommon & {
  color: Exclude<PlayerColor, PlayerColor.White>
  meeplesStayingInJail?: number
  gold: number
  spied: (District | undefined)[]
}

export default ThiefState
