import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import PlayerView from '@gamepark/brigands/players/PlayerView'

enum PlayerPosition {
  TOP_LEFT, TOP, TOP_RIGHT,
  BOTTOM_LEFT, BOTTOM, BOTTOM_RIGHT
}

export default PlayerPosition

export function getPlayerPosition(players: PlayerView[], playerColor: PlayerColor, playerId?: PlayerColor): PlayerPosition {
  if (playerColor === PlayerColor.White) return players.length > 3 ? PlayerPosition.BOTTOM : PlayerPosition.TOP
  if (playerColor === playerId) return PlayerPosition.TOP_LEFT
  const thiefIndex = players
    .filter(player => player.color !== playerId && player.color !== PlayerColor.White)
    .findIndex(player => player.color === playerColor)
  const positionIndex = playerId && playerId !== PlayerColor.White ? thiefIndex + 1 : thiefIndex
  if (players.length <= 3) {
    return positionIndex === 0 ? PlayerPosition.TOP_LEFT : PlayerPosition.TOP_RIGHT
  } else if (players.length <= 5) {
    return positionIndex
  } else {
    return positionIndex === 4 ? PlayerPosition.BOTTOM_RIGHT : positionIndex
  }
}