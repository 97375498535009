/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {faQuestion} from '@fortawesome/free-solid-svg-icons/faQuestion'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {getCurrentDistrict} from '@gamepark/brigands/Brigands'
import District from '@gamepark/brigands/districts/District'
import GameView from '@gamepark/brigands/GameView'
import {chooseActionMove} from '@gamepark/brigands/moves/ChooseAction'
import Move from '@gamepark/brigands/moves/Move'
import Phase from '@gamepark/brigands/Phase'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {PLAYER_MEEPLES} from '@gamepark/brigands/players/PlayerState'
import ActionType from '@gamepark/brigands/tokens/ActionType'
import TokenAction from '@gamepark/brigands/tokens/TokenAction'
import {usePlay} from '@gamepark/react-client'
import {Dialog} from '@gamepark/react-components'
import {TFunction} from 'i18next'
import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Button from '../../utils/Button'
import {dialogCss, shineEffect} from '../../utils/styles'
import ActionRules from './ActionRules'
import ActionWheel, {arcCss, thievesTargetOrder, wheelSize} from './ActionWheel'

type Props = {
  playerId: PlayerColor
  district: District
  targets?: PlayerColor[]
  playerAction?: TokenAction
  tutorial?: boolean
}

const wheelAnimationMS = 200

export default function PlayerActionWheel({playerId, district, targets, playerAction, tutorial}: Props) {
  const {t} = useTranslation()
  const mustChoose = targets !== undefined
  const [wheelUp, setWheelUp] = useState(mustChoose)
  const [dialogOpen, setDialogOpen] = useState(mustChoose)
  const [action, setAction] = useState(playerAction)
  const [helpOpen, setHelpOpen] = useState(false)
  const play = usePlay()
  useEffect(() => {
    if (mustChoose) setWheelUp(true)
  }, [mustChoose])
  useEffect(() => {
    if (wheelUp) {
      const timeout = setTimeout(() => setDialogOpen(true), wheelAnimationMS)
      return () => clearTimeout(timeout)
    }
    return
  }, [wheelUp])
  useEffect(() => {
    if (!dialogOpen) {
      setAction(undefined)
      const timeout = setTimeout(() => setWheelUp(false), wheelAnimationMS)
      return () => clearTimeout(timeout)
    }
    return
  }, [dialogOpen])
  const setOrUnsetAction = useCallback((action: TokenAction) =>
    setAction(previous => previous?.type !== action.type || previous?.target !== action.target ? action : undefined), [])
  const playAndClose = (move: Move) => {
    play(move)
    setDialogOpen(false)
  }
  const closeDisabled = tutorial && mustChoose
  return <>
    <ActionWheel playerColor={playerId} css={[wheelDisplayPosition, pointer, !wheelUp && translateBottom]}
                 onClick={() => setWheelUp(true)}/>
    <Dialog open={dialogOpen} onBackdropClick={() => !closeDisabled && setDialogOpen(false)} backdropCss={backdropCss}>
      {!closeDisabled && <button css={[iconButton, closeButton]} onClick={() => setDialogOpen(false)}><FontAwesomeIcon icon={faXmark}/></button>}
      <button css={[iconButton, helpButton]} onClick={() => setHelpOpen(open => !open)}><FontAwesomeIcon icon={faQuestion}/></button>
      <div css={[wheelAction, mustChoose && (tutorial ? unavailableEffect : shineEffect), action?.type === ActionType.GoHome && selected, playerAction?.type === ActionType.GoHome && played,
        arcCss(45, 135)]} onClick={() => setOrUnsetAction({type: ActionType.GoHome})}/>
      {thievesTargetOrder[playerId].map((thief, index, thieves) =>
        <div key={thief} css={[wheelAction, mustChoose && (targets.includes(thief) ? shineEffect : unavailableEffect),
          action?.type === ActionType.Steal && action.target === thief && selected,
          playerAction?.type === ActionType.Steal && playerAction.target === thief && played,
          arcCss(45 - index * 90 / thieves.length, 45 - (index + 1) * 90 / thieves.length)]}
             onClick={() => setOrUnsetAction({type: ActionType.Steal, target: thief})}/>
      )}
      <div css={[wheelAction, mustChoose && (tutorial ? unavailableEffect : shineEffect), action?.type === ActionType.Move && selected, playerAction?.type === ActionType.Move && played,
        arcCss(-45, -135)]} onClick={() => setOrUnsetAction({type: ActionType.Move})}/>
      {playerId === PlayerColor.White ?
        <div css={[wheelAction, mustChoose && (targets.length > 0 ? shineEffect : unavailableEffect),
          action?.type === ActionType.Spy && selected, playerAction?.type === ActionType.Spy && played,
          arcCss(135, 225)]} onClick={() => setOrUnsetAction({type: ActionType.Spy})}/>
        :
        thievesTargetOrder[playerId].map((thief, index, thieves) =>
          <div key={thief} css={[wheelAction, targets && (targets.includes(thief) && !tutorial ? shineEffect : unavailableEffect),
            action?.type === ActionType.Push && action.target === thief && selected,
            playerAction?.type === ActionType.Push && playerAction.target === thief && played,
            arcCss(225 - index * 90 / thieves.length, 225 - (index + 1) * 90 / thieves.length)]}
               onClick={() => setOrUnsetAction({type: ActionType.Push, target: thief})}/>
        )
      }
      {helpOpen &&
        <div css={[dialogCss, wheelInfo]}>
          <button css={closeHelpButton} onClick={() => setHelpOpen(false)}><FontAwesomeIcon icon={faXmark}/></button>
          <h2>{t('Action wheel')}</h2>
          <p>{t('action.wheel.info')}</p>
        </div>
      }
      {action &&
        <div css={[dialogCss, actionInfo]}>
          <button css={closeHelpButton} onClick={() => setAction(undefined)}><FontAwesomeIcon icon={faXmark}/></button>
          <h2>{actionsTitle[action.type](t, playerId === PlayerColor.White)}</h2>
          <ActionRules action={action} playerId={playerId}/>
          {mustChoose && ((!action.target || targets.includes(action.target)) ?
              <Button playerColor={action.target ?? playerId} onClick={() => playAndClose(chooseActionMove(playerId, action, district))}>
                {t('Choose this action')}
              </Button>
              :
              <p>{t('action.unavailable')}</p>
          )}
        </div>
      }
    </Dialog>
  </>
}

const wheelLeft = 111
const wheelTop = 45
const wheelHiddenLeft = 57
const wheelHiddenTop = 89

const wheelDisplayPosition = css`
  position: absolute;
  left: ${wheelLeft}em;
  top: ${wheelTop}em;
  z-index: 1101;
  transition: transform ${wheelAnimationMS}ms ease-in-out;
`

const pointer = css`
  cursor: pointer;
`

const translateBottom = css`
  z-index: 1;
  transform: translate(${wheelHiddenLeft - wheelLeft}em, ${wheelHiddenTop - wheelTop}em);

  &:hover {
    transform: translate(${wheelHiddenLeft - wheelLeft}em, ${wheelHiddenTop - wheelTop - 3}em);
  }
`

const backdropCss = css`
  background: none;
  z-index: 900;
`

const wheelAction = css`
  position: absolute;
  width: ${wheelSize}em;
  height: ${wheelSize}em;
  left: calc(50% - ${50 * 16 / 9 - wheelLeft}em);
  top: ${wheelTop}em;
  border-radius: 50%;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.3);
  }
`

const selected = css`
  background: rgba(255, 255, 255, 0.5);
`

const played = css`
  background: rgba(0, 128, 0, 0.5);
`

const unavailableEffect = css`
  background: rgba(0, 0, 0, 0.3);
`

export function getActionTargets(game: GameView, playerId: PlayerColor): PlayerColor[] | undefined {
  if (game.phase !== Phase.Solving) return
  const district = getCurrentDistrict(game)
  const player = game.players.find(player => player.color === playerId)!
  if (!player.meeples.includes(district) || !player.tokens.includes(district) || player.action) return
  return game.players.filter(player => player.color !== playerId && player.meeples.slice(0, PLAYER_MEEPLES).includes(district)).map(player => player.color)
}

const wheelInfo = css`
  position: absolute;
  left: calc(50% - 70em);
  top: 50em;
  transform: translateY(-50%);
  width: 70em;
`


const actionInfo = css`
  position: absolute;
  left: calc(50% - ${50 * 16 / 9 - wheelLeft + 15}em);
  top: 10em;
  width: 80em;
`

const iconButton = css`
  border: none;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  width: 6em;
  height: 6em;
  color: white;

  > * {
    font-size: 4em;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: black;
  }
`

const closeButton = css`
  position: absolute;
  top: ${wheelTop + 1}em;
  left: calc(50% - ${50 * 16 / 9 - wheelLeft - 43}em);
`

const helpButton = css`
  position: absolute;
  top: ${wheelTop + 7}em;
  left: calc(50% - ${50 * 16 / 9 - wheelLeft - 47.7}em);
`

const closeHelpButton = css`
  position: absolute;
  top: 1em;
  right: 1em;
  font-size: 1em !important;
  color: black;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  width: 6em;
  height: 6em;

  > * {
    font-size: 4em;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`

const actionsTitle: Record<ActionType, (t: TFunction, prince: boolean) => string> = {
  [ActionType.Spy]: t => t('action.0'),
  [ActionType.GoHome]: (t, prince) => prince ? t('action.1.prince') : t('action.1'),
  [ActionType.Steal]: t => t('action.2'),
  [ActionType.Move]: t => t('action.3'),
  [ActionType.Push]: t => t('action.4'),
  [ActionType.Pass]: () => ''
}
