/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {Draggable} from '@gamepark/react-components'
import {DraggableProps} from '@gamepark/react-components/dist/Draggable/Draggable'
import ActionToken from './ActionToken'

type Props = {
  playerColor: PlayerColor
  selected?: boolean
} & Omit<DraggableProps<ActionTokenItem>, 'type'>

export const ACTION_TOKEN = 'ActionToken'

export type ActionTokenItem = { token: number }

export function DraggableActionToken({playerColor, selected = false, ...props}: Props) {
  return (
    <Draggable type={ACTION_TOKEN} {...props}>
      <ActionToken playerColor={playerColor} css={selected ? selectedCss : props.canDrag && highlightCss}/>
    </Draggable>
  )
}

export const selectedCss = css`
  filter: brightness(150%);
  transform: scale(1.1);
`

const highlightKeyframes = keyframes`
  0%, 50% {
    filter: brightness(100%);
    transform: scale(1);
  }
  100% {
    filter: brightness(150%);
    transform: scale(1.1);
  }
`

const highlightCss = css`
  animation: ${highlightKeyframes} 1.5s infinite alternate;
`