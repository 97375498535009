import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import MoveType from './MoveType'

type DiscardDayCard = {
  type: typeof MoveType.DiscardDayCard
  district: District
}

export default DiscardDayCard

export function discardDayCardMove(district: District): DiscardDayCard {
  return {type: MoveType.DiscardDayCard, district}
}

export function discardDayCard(state: GameState | GameView, move: DiscardDayCard) {
  state.dayCards.splice(state.dayCards.indexOf(move.district), 1)
}
