import PlayerColor from './PlayerColor'
import PlayerState from './PlayerState'
import PlayerView from './PlayerView'
import PrinceState from './PrinceState'
import PrinceView from './PrinceView'

export default function isPrince(player: PlayerState): player is PrinceState
export default function isPrince(player: PlayerView): player is PrinceView
export default function isPrince(player: PlayerState | PlayerView): player is PrinceState | PrinceView
export default function isPrince(player: PlayerState | PlayerView): player is PrinceState | PrinceView {
  return player.color === PlayerColor.White
}
