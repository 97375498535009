/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {MeepleLocation} from '@gamepark/brigands/players/PlayerCommon'
import {Draggable} from '@gamepark/react-components'
import {DraggableProps} from '@gamepark/react-components/dist/Draggable/Draggable'
import Meeple from './Meeple'

type Props = {
  meepleColor: PlayerColor
  meeple: number
  location: MeepleLocation
  selected?: boolean
} & Omit<DraggableProps, 'type'>

export const MEEPLE = 'Meeple'

export type MeepleItem = { meeple: number, location: MeepleLocation }

export function isMeepleItem(item: any): item is MeepleItem {
  return typeof item?.meeple === 'number'
}

export default function DraggableMeeple({meepleColor, meeple, location, selected = false, ...props}: Props) {
  return (
    <Draggable type={MEEPLE} item={{meeple, location}} {...props}>
      <Meeple meepleColor={meepleColor} css={selected ? selectedCss : props.canDrag && highlightCss}/>
    </Draggable>
  )
}

export const selectedCss = css`
  filter: brightness(150%) drop-shadow(0 0 0.2em black);
  transform: scale(1.1);
`

const highlightKeyframes = keyframes`
  0%, 50% {
    filter: brightness(100%) drop-shadow(0 0 0.2em black);
    transform: scale(1);
  }
  100% {
    filter: brightness(150%) drop-shadow(0 0 0.2em black);
    transform: scale(1.1);
  }
`

const highlightCss = css`
  animation: ${highlightKeyframes} 1.5s infinite alternate;
`