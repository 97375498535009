import DrawDayCard from '@gamepark/brigands/moves/DrawDayCard'
import MoveType from '@gamepark/brigands/moves/MoveType'
import PlaceMeeple from '@gamepark/brigands/moves/PlaceMeeple'
import PlaceToken from '@gamepark/brigands/moves/PlaceToken'
import ThrowDices from '@gamepark/brigands/moves/ThrowDices'
import StealGold from '@gamepark/brigands/moves/StealGold'
import Phase from '@gamepark/brigands/Phase'
import {useAnimation} from '@gamepark/react-client'
import {FC, useEffect} from 'react'
import {AudioLoader} from '../utils/AudioLoader'
import CardFlip from './cardFlip.mp3'
import DiceShake from './diceShake.mp3'

import GoldCoinSound from './gold1.mp3'
import GoldBagSound from './goldBag.mp3'
import MoveTokenSound from './moveToken.mp3'
//import PrisonDoor from './prisonDoor.mp3'
import EndSound from './trumpet.mp3'

type Props = {
  audioLoader: AudioLoader
  phase?: Phase
}

const BrigandsSounds: FC<Props> = ({audioLoader, phase}) => {

  const placingMeeple = useAnimation<PlaceMeeple>(animation => animation.move.type === MoveType.PlaceMeeple)
  const placingToken = useAnimation<PlaceToken>(animation => animation.move.type === MoveType.PlaceToken)
  const stealGoldAnimation = useAnimation<StealGold>(animation => animation.move.type === MoveType.StealGold)
  const drawEventAnimation = useAnimation<DrawDayCard>(animation => animation.move.type === MoveType.DrawDayCard)
  const diceRollAnimation = useAnimation<ThrowDices>(animation => animation.move.type === MoveType.ThrowDices)

  useEffect(() => {
    // TODO: when player places its own meeples & tokens, there is no animation duration. However, this sound should be played.
    if (placingMeeple || placingToken) {
      audioLoader.play(MoveTokenSound, false, 0.6)
    }
  }, [placingMeeple?.move, placingToken?.move])

  useEffect(() => {
    if (stealGoldAnimation && stealGoldAnimation.move.gold > 0) {
      stealGoldAnimation.move.gold > 5 ? audioLoader.play(GoldBagSound, false, 0.6) : audioLoader.play(GoldCoinSound, false, 0.4)
    }
  }, [stealGoldAnimation?.move])

  useEffect(() => {
    if (drawEventAnimation) {
      audioLoader.play(CardFlip, false, 0.4)
    }
  }, [drawEventAnimation?.move])

  useEffect(() => {
    if (diceRollAnimation) {
      audioLoader.play(DiceShake, false, 0.4)
    }
  }, [diceRollAnimation?.move])

  /*useEffect(() => {
    if (arrestPartnersAnimation) {
      audioLoader.play(PrisonDoor, false, 0.4)
    }
  }, [arrestPartnersAnimation?.move])*/

  useEffect(() => {
    if (phase === undefined) {
      audioLoader.play(EndSound, false, 0.4)
    }
  }, [phase])

  return null
}

export default BrigandsSounds