import {arrestEveryone} from '../Brigands'
import Move from '../moves/Move'
import {throwDicesMove, ThrowDicesRandomized} from '../moves/ThrowDices'
import District from './District'
import {DistrictRules} from './DistrictRules'

export default class Convoy extends DistrictRules {
  district = District.Convoy

  getAutomaticMoves(): Move[] {
    const meeples = this.countMeeples()
    if (meeples === 1) {
      return arrestEveryone(this.state, this.district)
    } else {
      return [throwDicesMove(this.hasDayCard() ? 2 : 1, this.district)]
    }
  }

  onThrowDices(move: ThrowDicesRandomized) {
    const gold = 10 + move.result.reduce((sum, dice) => sum + dice)
    this.state.nextMoves.push(...this.shareGold(gold))
    this.state.nextMoves.push(...this.takeBackMeeplesMoves())
  }
}
