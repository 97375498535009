/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import District from '@gamepark/brigands/districts/District'
import {TokenLocation} from '@gamepark/brigands/players/PlayerCommon'
import PlayerView from '@gamepark/brigands/players/PlayerView'
import {Dialog, Picture} from '@gamepark/react-components'
import {FC, useState} from 'react'
import Images from '../images/Images'
import DayCards from './DayCards'
import DistrictTile, {districtAngle} from './DistrictTile'
import {dialogCloseIcon, dialogCss, districtImageRatio, districtWidth} from '../utils/styles'
import {useTranslation} from 'react-i18next'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'

type Props = {
  city: District[]
  player?: PlayerView
  currentDistrict?: District
  selectedMeeple?: number
  selectedToken?: TokenLocation
  deck: number
  dayCards: District[]
}

const City: FC<Props> = ({city, player, currentDistrict, selectedMeeple, selectedToken, deck, dayCards}) => {
  const {t} = useTranslation()
  const [newTurnDialogOpen, setNewTurnDialogOpen] = useState(false)
  return (
    <>
      <Picture src={Images.districtStart} css={[districtSize, districtPosition]}/>
      <div css={newTurnInfo} onClick={() => setNewTurnDialogOpen(true)}/>
      <DistrictTile district={District.Jail} selectedMeeple={selectedMeeple} selectedToken={selectedToken} player={player}
                    darken={currentDistrict && currentDistrict !== District.Jail}/>
      {city.map((district, index) =>
        <DistrictTile key={district} css={[districtPosition, districtRotation(index)]}
                      district={district} selectedMeeple={selectedMeeple} selectedToken={selectedToken} player={player}
                      darken={currentDistrict && currentDistrict !== district}/>
      )}
      <DayCards deck={deck} dayCards={dayCards} city={city}/>
      <Dialog open={newTurnDialogOpen} onBackdropClick={() => setNewTurnDialogOpen(false)} css={dialogCss}>
        <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={() => setNewTurnDialogOpen(false)}/>
        <h2>{t('new.turn')}</h2>
        <p>{t('new.turn.rules')}</p>
      </Dialog>
    </>
  )
}

const districtSize = css`
  width: ${districtWidth}em;
  height: ${districtWidth * districtImageRatio}em;
`

const districtPosition = css`
  position: absolute;
  top: 68em;
  left: 32em;
`

const districtRotation = (index: number) => css`
  transform-origin: center -46%;
  transform: rotate(${districtAngle(index) + 90}deg);
`

const newTurnInfo = css`
  position: absolute;
  top: 92.5em;
  left: 39em;
  width: 17.3em;
  height: 5.2em;
  border-radius: 1em;
  cursor: help;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`

export default City