/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import District from '@gamepark/brigands/districts/District'
import MoveType from '@gamepark/brigands/moves/MoveType'
import SpendTokens, {spendTokensMove} from '@gamepark/brigands/moves/SpendTokens'
import TakeToken, {takeTokenMove} from '@gamepark/brigands/moves/TakeToken'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {MAX_TOKENS, STOCK, TokenLocation} from '@gamepark/brigands/players/PlayerCommon'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Dialog, DialogProps, Picture} from '@gamepark/react-components'
import {useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Images from '../../images/Images'
import Button from '../../utils/Button'
import {dialogCloseIcon, dialogCss} from '../../utils/styles'

type Props = {
  tokens: TokenLocation[]
  dayCard: boolean
  close: () => void
} & DialogProps

export default function HarborDialog({tokens, dayCard, close, ...props}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()!
  const [move, setMove] = useState<TakeToken | SpendTokens>()
  const play = usePlay()
  const tokensInStock = tokens.reduce((sum, token) => token === STOCK ? sum + 1 : sum, 0)
  const onPlayButtonClick = useCallback(() => {
    if (move) {
      play(move)
      close()
    }
  }, [play, move, close])
  useEffect(() => {
    setMove(undefined)
  }, [tokensInStock])
  return (
    <Dialog css={dialogCss} {...props} onBackdropClick={close}>
      <h2>{t('district.choice.title')}</h2>
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={close}/>
      <div css={flexContainer}>
        <Picture src={dayCard ? Images.harborTake3 : Images.harborTake2}
                 onClick={() => tokens.length <= MAX_TOKENS && setMove(takeTokenMove(playerId, District.Harbor))}
                 css={[move?.type === MoveType.TakeToken && selectedCss, tokens.length === MAX_TOKENS && unavailable]}/>
        <Picture src={Images.harborSell}
                 onClick={() => tokensInStock > 0 && setMove(spendTokensMove(playerId, District.Harbor, tokensInStock))}
                 css={[move?.type === MoveType.SpendTokens && selectedCss, tokensInStock === 0 && unavailable]}/>
      </div>
      {move?.type === MoveType.SpendTokens &&
        <Button playerColor={playerId} disabled={move.tokens === 1} css={quantityButton}
                onClick={() => setMove(spendTokensMove(playerId, District.Harbor, move.tokens - 1))}>-</Button>
      }
      <Button playerColor={playerId} disabled={!move} onClick={onPlayButtonClick}>
        {!move ? t('select.action')
          : move?.type === MoveType.TakeToken ? t('harbor.choice.take', {tokens: dayCard ? 3 : 2})
            : t('harbor.choice.spend', {tokens: move.tokens})}
      </Button>
      {move?.type === MoveType.SpendTokens &&
        <Button playerColor={playerId} disabled={move.tokens === tokensInStock} css={quantityButton}
                onClick={() => setMove(spendTokensMove(playerId, District.Harbor, move.tokens + 1))}>+</Button>
      }
    </Dialog>
  )
}

const actionWidth = 40

const flexContainer = css`
  display: flex;
  max-width: 180em;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3em;

  img {
    margin: 1em;
    width: ${actionWidth}em;
    height: ${actionWidth * 220 / 465}em;
    cursor: pointer;
  }
`

const selectedCss = css`
  filter: drop-shadow(0.5em 0 darkgreen) drop-shadow(-0.5em 0 darkgreen) drop-shadow(0 0.5em darkgreen) drop-shadow(0 -0.5em darkgreen);
`

const unavailable = css`
  cursor: auto !important;
  filter: grayscale();
`

const quantityButton = css`
  width: 2em;
  margin: 0 3em;
`