import {getDistrictRules} from '../Brigands'
import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import isThief from '../players/IsThief'
import PlayerColor from '../players/PlayerColor'
import MoveType from './MoveType'

type StealGold = {
  type: MoveType.StealGold
  player: PlayerColor
  gold: number
  district: District
  victim?: PlayerColor
}

export default StealGold

export function stealGoldMove(player: PlayerColor, gold: number, district: District, victim?: PlayerColor): StealGold {
  const move: StealGold = {type: MoveType.StealGold, player, gold, district}
  if (victim) move.victim = victim
  return move
}

export function stealGold(state: GameState | GameView, move: StealGold) {
  const player = state.players.find(player => player.color === move.player)
  const victim = state.players.find(player => player.color === move.victim)
  if (victim && isThief(victim)) {
    victim.gold -= move.gold
  }
  if (player && isThief(player)) {
    player.gold += move.gold
  }
  getDistrictRules(state).onStealGold(move)
}
