import {countPlayerMeeples} from '../Brigands'
import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import Move from '../moves/Move'
import {placeMeepleMove} from '../moves/PlaceMeeple'
import {PLAYER_MEEPLES} from '../players/PlayerState'
import ActionType from './ActionType'

export default function pushToNextDistrict(game: GameState | GameView, district: District): Move[] {
  const moves: Move[] = []
  for (const player of game.players) {
    if (player.action?.type === ActionType.Push) {
      const target = player.action.target
      const victim = game.players.find(player => player.color === target)!
      const nextDistrict = game.city[game.city.indexOf(district) + 1] ?? District.Jail
      let pushLeft = countPlayerMeeples(player, district)
      for (let meeple = 0; meeple < PLAYER_MEEPLES && pushLeft > 0; meeple++) {
        if (victim.meeples[meeple] === district) {
          moves.push(placeMeepleMove(victim.color, meeple, nextDistrict))
          pushLeft--
        }
      }
    }
  }
  return moves
}