/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import {faDiceFive} from '@fortawesome/free-solid-svg-icons/faDiceFive'
import {faDiceFour} from '@fortawesome/free-solid-svg-icons/faDiceFour'
import {faDiceOne} from '@fortawesome/free-solid-svg-icons/faDiceOne'
import {faDiceSix} from '@fortawesome/free-solid-svg-icons/faDiceSix'
import {faDiceThree} from '@fortawesome/free-solid-svg-icons/faDiceThree'
import {faDiceTwo} from '@fortawesome/free-solid-svg-icons/faDiceTwo'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import MoveType from '@gamepark/brigands/moves/MoveType'
import {ThrowDicesRandomized} from '@gamepark/brigands/moves/ThrowDices'
import {useAnimation} from '@gamepark/react-client'
import {useEffect, useState} from 'react'

export default function DiceAnimation() {
  const diceAnimation = useAnimation<ThrowDicesRandomized>(animation => animation.move.type === MoveType.ThrowDices)
  const [dice, setDice] = useState<number[]>(diceAnimation?.move.result ?? [])
  useEffect(() => {
    if (diceAnimation?.move.result.length) {
      setDice(diceAnimation.move.result)
      setTimeout(() => setDice([]), diceAnimation.duration * 2 * 1000)
    }
  }, [diceAnimation])
  return (
    <>
      {dice.map((die, index) =>
        <div key={index} css={[diePosition(index), dieSize, dieRotation, diceAnimation && dieRotationAnimation(getRandomRotation(), diceAnimation.duration)]}>
          <div css={[dieStyle, dicesFaceCss[(1 - die + 6) % 6]]}>
            <FontAwesomeIcon icon={faDiceOne}/>
          </div>
          <div css={[dieStyle, dicesFaceCss[(2 - die + 6) % 6]]}>
            <FontAwesomeIcon icon={faDiceTwo}/>
          </div>
          <div css={[dieStyle, dicesFaceCss[(3 - die + 6) % 6]]}>
            <FontAwesomeIcon icon={faDiceThree}/>
          </div>
          <div css={[dieStyle, dicesFaceCss[(4 - die + 6) % 6]]}>
            <FontAwesomeIcon icon={faDiceFour}/>
          </div>
          <div css={[dieStyle, dicesFaceCss[(5 - die + 6) % 6]]}>
            <FontAwesomeIcon icon={faDiceFive}/>
          </div>
          <div css={[dieStyle, dicesFaceCss[(6 - die + 6) % 6]]}>
            <FontAwesomeIcon icon={faDiceSix}/>
          </div>
        </div>
      )}
    </>
  )
}

const size = 5

const diePosition = (index: number) => css`
  position: absolute;
  top: ${index === 0 ? 10 : index === 1 ? 18 : 9}em;
  left: ${index === 0 ? 76 : index === 1 ? 83 : 85}em;
`

const dieSize = css`
  width: ${size}em;
  height: ${size}em;
`

const dieRotationKeyFrames = (rotationVector: number[]) => keyframes`
  from {
    transform: rotate3d(${rotationVector[0]}, ${rotationVector[1]}, ${rotationVector[2]}, ${rotationVector[3]}deg);
  }
  80% {
    transform: rotate3d(0, 0, 0, 0deg);
  }
  to {
    transform: rotate3d(0, 0, 0, 0deg);
  }
`

const dieRotationAnimation = (rotationVector: number[], duration: number) => css`
  animation: ${dieRotationKeyFrames(rotationVector)} ${duration}s ease-out;
`

const dieRotation = css`
  transform-style: preserve-3d;
  transform-origin: center center 2.7em;
  transform: rotate3d(0, 0, 0, 0deg);
`

const dieStyle = css`
  position: absolute;
  color: black;
  background-color: white;
  border-radius: 15%;
  backface-visibility: visible;
  width: 100%;
  height: 100%;

  & > svg {
    width: 120%;
    height: 120%;
    transform: translate(-8%, -8%);
  }
`

const frontCss = css`
  transform: translateZ(${size}em);
`

const behindCss = css`
  transform: rotateX(180deg);
`

const topCss = css`
  transform: rotateX(90deg);
  transform-origin: top;
`

const bottomCss = css`
  transform: rotateX(-90deg);
  transform-origin: bottom;
`

const leftCss = css`
  transform: rotateY(-90deg);
  transform-origin: left;
`

const rightCss = css`
  transform: rotateY(90deg);
  transform-origin: right;
`

const dicesFaceCss = [frontCss, leftCss, topCss, bottomCss, rightCss, behindCss]

function getRandomRotation(): number[] {
  const maxVector: number = 10
  const minVector: number = -10

  return [Math.floor(Math.random() * (maxVector - minVector + 1)) + minVector,
    Math.floor(Math.random() * (maxVector - minVector + 1)) + minVector,
    Math.floor(Math.random() * (maxVector - minVector + 1)) + minVector,
    Math.floor(Math.random() * (3000 + 3000 + 1)) - 3000
  ]

}
