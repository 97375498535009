/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {Skill, upgradeSkillMove} from '@gamepark/brigands/moves/UpgradeSkill'
import {useDrop} from 'react-dnd'

export const SPY = 'SPY'
export const JUDGE = 'JUDGE'

type Props = {
  spy: number
  judge: number
}

export default function SkillDropArea({spy, judge}: Props) {
  const [{draggedType, isOver}, dropRef] = useDrop({
    accept: [SPY, JUDGE],
    collect: monitor => ({
      draggedType: monitor.getItemType(),
      isOver: monitor.isOver()
    }),
    drop: (_, monitor) => upgradeSkillMove(monitor.getItemType() === SPY ? Skill.Spy : Skill.Judge)
  })
  return (
    <div ref={dropRef} css={[
      areaCss,
      draggedType === SPY && spyLocation(spy),
      draggedType === JUDGE && judgeLocation(judge),
      isOver && overCss
    ]}/>
  )
}

const areaCss = css`
  position: absolute;
  width: 23.4%;
`

const spyLocation = (level: number) => css`
  height: ${(level === 2 ? 19 : 16.5)}%;
  left: 4.6%;
  bottom: ${5.5 + level * 16.5}%;
  background-color: rgba(0, 255, 0, 0.3);
`

const judgeLocation = (level: number) => css`
  height: ${level === 2 ? 17 : 14.5}%;
  left: 72.2%;
  bottom: ${(level + 1) * 14.5}%;
  background-color: rgba(0, 255, 0, 0.3);
`

const overCss = css`
  background-color: rgba(0, 255, 0, 0.6);
`
