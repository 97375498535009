import {Action} from '@gamepark/rules-api'
import {countPlayerMeeples, getCurrentDistrict} from './Brigands'
import GameState from './GameState'
import GameView from './GameView'
import Move from './moves/Move'
import {isMoveOnNextPhase} from './moves/MoveOnNextPhase'
import MoveType from './moves/MoveType'
import MoveView from './moves/MoveView'
import PlayerColor from './players/PlayerColor'

export default function canUndo(action: Action<Move | MoveView, PlayerColor>, consecutiveActions: Action<Move | MoveView, PlayerColor>[], state: GameState | GameView): boolean {
  switch (action.move.type) {
    case MoveType.PlaceMeeple:
      const district = action.move.district
      const player = state.players.find(player => player.color === action.playerId)!
      const isAlone = countPlayerMeeples(player, district) === 1
      const hasToken = player.tokens.includes(district)
      if (isAlone && hasToken) return false
      return !consecutiveActions.some(action => action.playerId === action.playerId && action.move.type === MoveType.Pass)
    case MoveType.PlaceToken:
      return !consecutiveActions.some(action => action.playerId === action.playerId && action.move.type === MoveType.Pass)
    case MoveType.Pass:
      return !action.consequences.some(isMoveOnNextPhase) && !consecutiveActions.some(action => action.consequences.some(isMoveOnNextPhase))
    case MoveType.ChooseAction:
      const isTokenDiscard = (move: Move) => move.type === MoveType.DiscardToken && move.player === action.playerId
      return !action.consequences.some(isTokenDiscard) && !consecutiveActions.some(action => action.consequences.some(isTokenDiscard))
    case MoveType.TakeToken:
    case MoveType.SpendTokens:
      return !action.consequences.some(isMoveOnNextPhase)
        && !consecutiveActions.some(consecutiveAction => consecutiveAction.playerId === action.playerId || consecutiveAction.consequences.some(isMoveOnNextPhase))
        && getCurrentDistrict(state) === action.move.district
    default:
      return false
  }
}
