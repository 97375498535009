/** @jsxImportSource @emotion/react */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Dialog, DialogProps} from '@gamepark/react-components'
import {Trans, useTranslation} from 'react-i18next'
import {dialogCloseIcon, dialogCss} from '../utils/styles'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {css} from '@emotion/react'
import Button from '../utils/Button'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Skill, upgradeSkillMove} from '@gamepark/brigands/moves/UpgradeSkill'
import PlayerView from '@gamepark/brigands/players/PlayerView'
import isPrince from '@gamepark/brigands/players/IsPrince'
import PrinceView from '@gamepark/brigands/players/PrinceView'
import District from '@gamepark/brigands/districts/District'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import isThief from '@gamepark/brigands/players/IsThief'
import DistrictCard from '../players/district-cards/DistrictCard'
import {districtsName} from './DistrictHelpDialog'
import PlayerState from '@gamepark/brigands/players/PlayerState'

type Props = {
  players: (PlayerView | PlayerState)[]
  currentDistrict?: District
  close: () => void
} & DialogProps

export default function SpyRulesDialog({players, close, currentDistrict, ...props}: Props) {
  const {t} = useTranslation()
  const play = usePlay()
  const playerId = usePlayerId()
  const prince = players.find(isPrince) as PrinceView
  const player = players.find(player => player.color === playerId)
  return (
    <Dialog css={[dialogCss, css`max-width: 160em;`]} onBackdropClick={close} {...props}>
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={close}/>
      <h2>{t('spy.title')}</h2>
      <p><Trans defaults="spy.rules" components={[<strong/>]}/></p>
      <div css={cardsDisplay}>
        {players.filter(isThief).flatMap(thief =>
          thief.spied.map((district, index) =>
            <DistrictCard key={`${thief.color}_${district ?? index}`} playerColor={thief.color} district={district} css={css`font-size: 2em;`}/>
          )
        )}
      </div>
      {player && isThief(player) && player.spied.length > 0 &&
          <>
              <p css={spiedCss}>
                {player.spied.length === 1 ? t('spied.1', {district: districtsName[player.spied[0]!](t)}) :
                  player.spied.length === 2 ? t('spied.2', {district1: districtsName[player.spied[0]!](t), district2: districtsName[player.spied[1]!](t)}) :
                    t('spied.3', {
                      district1: districtsName[player.spied[0]!](t),
                      district2: districtsName[player.spied[1]!](t),
                      district3: districtsName[player.spied[2]!](t)
                    })}
              </p>
              <p css={warnSpied}>{t('spied.warn')}</p>
          </>
      }
      {playerId === PlayerColor.White && currentDistrict === District.Jail && prince.meeples.includes(currentDistrict) &&
          <Button onClick={() => {
            play(upgradeSkillMove(Skill.Spy))
            close()
          }}>
            {t('spy.upgrade')}
          </Button>
      }
    </Dialog>
  )
}

const cardsDisplay = css`
  width: 100%;
  display: flex;
  overflow-x: auto;
  padding: 1em;

  > * {
    flex-shrink: 0;
    margin-right: 1em;
  }
`

const spiedCss = css`
  text-align: start !important;
`

const warnSpied = css`
  text-align: start !important;
  color: darkred;
`