/** @jsxImportSource @emotion/react */
import District from '@gamepark/brigands/districts/District'
import MoveType from '@gamepark/brigands/moves/MoveType'
import ThiefView from '@gamepark/brigands/players/ThiefView'
import {usePlay} from '@gamepark/react-client'
import {useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import HeaderButton from '../../HeaderButton'
import {districtsName} from '../DistrictHelpDialog'
import TavernDialog from './TavernDialog'

type Props = {
  player: ThiefView
}

export default function TavernActionHeader({player}: Props) {
  const {t} = useTranslation()
  const [movePlayed, setMovePlayed] = useState(false)
  const [open, setOpen] = useState(true)
  const play = usePlay()
  useEffect(() => {
    setMovePlayed(false)
    setOpen(true)
  }, [player.meeples])
  return (
    <>
      {movePlayed ?
        t('tavern.roll.you', {dice: 1})
        :
        <Trans defaults="district.choice" values={{district: districtsName[District.Tavern](t)}} components={[
          <HeaderButton color={player.color} onClick={() => setOpen(true)}/>
        ]}/>
      }
      <TavernDialog open={open} close={() => setOpen(false)} gold={player.gold}
                    play={move => {
                      play(move, {delayed: move.type === MoveType.ThrowDices})
                      setMovePlayed(true)
                      setOpen(false)
                    }}/>
    </>
  )
}