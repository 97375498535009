/** @jsxImportSource @emotion/react */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import District from '@gamepark/brigands/districts/District'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {usePlayerId} from '@gamepark/react-client'
import {Dialog, DialogProps, Picture} from '@gamepark/react-components'
import {TFunction} from 'i18next'
import {useTranslation} from 'react-i18next'
import Button from '../utils/Button'
import {dialogCloseIcon, dialogCss, districtImageRatio, districtWidth} from '../utils/styles'
import {districtImage} from './DistrictTile'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {css} from '@emotion/react'

type Props = {
  district: District
  spied?: boolean
  close: () => void
} & DialogProps

export default function DistrictHelpDialog({district, spied, close, ...props}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()
  return (
    <Dialog css={dialogCss} onBackdropClick={close} {...props}>
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={close}/>
      <h2>{districtsName[district](t)}</h2>
      <Picture css={districtSize} src={districtImage[district]}/>
      <p>{districtRules[district](t)}</p>
      {spied && <p css={spiedWarn}>{t('district.spied')}</p>}
      <Button playerColor={playerId} onClick={close}>{t('OK')}</Button>
    </Dialog>
  )
}

export const districtsName: Record<District, (t: TFunction) => string> = {
  [District.Jail]: t => t('Jail'),
  [District.Tavern]: t => t('Tavern'),
  [District.Market]: t => t('Market'),
  [District.Harbor]: t => t('Harbor'),
  [District.CityHall]: t => t('City Hall'),
  [District.Treasure]: t => t('Treasure'),
  [District.Palace]: t => t('Palace'),
  [District.Convoy]: t => t('Convoy')
}

const districtRules: Record<District, (t: TFunction) => string> = {
  [District.Jail]: t => t('jail.rules'),
  [District.Tavern]: t => t('tavern.rules'),
  [District.Market]: t => t('market.rules'),
  [District.Harbor]: t => t('harbor.rules', {tokens: 2}),
  [District.CityHall]: t => t('cityHall.rules', {gold: 10}),
  [District.Treasure]: t => t('treasure.rules', {gold: 10}),
  [District.Palace]: t => t('palace.rules', {gold: 5}),
  [District.Convoy]: t => t('convoy.rules', {dice: 1})
}

const spiedWarn = css`
  font-weight: bold;
  color: darkred;
`

const districtSize = css`
  width: ${districtWidth * 1.2}em;
  height: ${districtWidth * districtImageRatio * 1.2}em;
`
