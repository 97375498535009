import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import PlayerColor from '../players/PlayerColor'
import {STOCK} from '../players/PlayerCommon'
import MoveType from './MoveType'

type PlaceToken = {
  type: MoveType.PlaceToken
  player: PlayerColor
  district: District
}

export default PlaceToken

export function placeTokenMove(player: PlayerColor, district: District): PlaceToken {
  return {type: MoveType.PlaceToken, player, district}
}

export function placeToken(state: GameState | GameView, move: PlaceToken) {
  const player = state.players.find(player => player.color === move.player)!
  player.tokens[player.tokens.indexOf(STOCK)] = move.district
}

export function placeTokenInView(state: GameView, move: PlaceToken) {
  placeToken(state, move)
  const player = state.players.find(player => player.color === move.player)!
  const token = (state.selectedToken && player.tokensPlace.find(token => token.location === state.selectedToken)!)
    || player.tokensPlace.filter(token => token.location === STOCK).reduce((token, current) => current.position > token.position ? current : token)
  token.location = move.district
  token.position = 0
}
