/** @jsxImportSource @emotion/react */
import District from '@gamepark/brigands/districts/District'
import {useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import HeaderButton from '../../HeaderButton'
import PassActionDialog from './PassActionDialog'
import {shineEffect} from '../../utils/styles'
import {usePlayerId} from '@gamepark/react-client'
import {districtsName} from '../../board/DistrictHelpDialog'

type Props = {
  district: District
}

export default function PassActionHeader({district}: Props) {
  const {t} = useTranslation()
  const [open, setOpen] = useState(false)
  const playerId = usePlayerId()
  return (
    <>
      <Trans defaults="action.choose" values={{district: districtsName[district](t)}} components={[
        <HeaderButton css={shineEffect} onClick={() => setOpen(true)} color={playerId}/>
      ]}/>
      <PassActionDialog open={open} close={() => setOpen(false)} district={district}/>
    </>
  )
}