/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {countPlayerMeeples} from '@gamepark/brigands/Brigands'
import District from '@gamepark/brigands/districts/District'
import MoveType from '@gamepark/brigands/moves/MoveType'
import SpendTokens, {spendTokensMove} from '@gamepark/brigands/moves/SpendTokens'
import TakeToken, {takeTokenMove} from '@gamepark/brigands/moves/TakeToken'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {MAX_TOKENS, STOCK} from '@gamepark/brigands/players/PlayerCommon'
import ThiefView from '@gamepark/brigands/players/ThiefView'
import {usePlay, usePlayerId} from '@gamepark/react-client'
import {Dialog, DialogProps, Picture} from '@gamepark/react-components'
import {useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Images from '../../images/Images'
import Button from '../../utils/Button'
import {dialogCloseIcon, dialogCss} from '../../utils/styles'

type Props = {
  player: ThiefView
  close: () => void
} & DialogProps

export default function JailDialog({player, close, ...props}: Props) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()!
  const [move, setMove] = useState<TakeToken | SpendTokens>()
  const play = usePlay()
  const tokensInStock = player.tokens.reduce((sum, token) => token === STOCK ? sum + 1 : sum, 0)
  const onPlayButtonClick = useCallback(() => {
    if (move) {
      play(move)
      close()
    }
  }, [play, move, close])
  return (
    <Dialog css={dialogCss} {...props} onBackdropClick={close}>
      <h2>{t('district.choice.title')}</h2>
      <p>{t('jail.meeples', {inJail: countPlayerMeeples(player, District.Jail), tokens: player.tokens.length})}</p>
      {!!player.meeplesStayingInJail && <p>{t('jail.meeples.stay', {stayingInJail: player.meeplesStayingInJail})}</p>}
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={close}/>
      <div css={flexContainer}>
        <Picture src={Images.jailFree}
                 onClick={() => tokensInStock > 0 && setMove(spendTokensMove(playerId, District.Jail))}
                 css={[move?.type === MoveType.SpendTokens && selectedCss, tokensInStock === 0 && unavailable]}/>
        <Picture src={Images.jailRemain}
                 onClick={() => player.tokens.length <= MAX_TOKENS && setMove(takeTokenMove(playerId, District.Jail))}
                 css={[move?.type === MoveType.TakeToken && selectedCss, player.tokens.length === MAX_TOKENS && unavailable]}/>
      </div>
      <Button playerColor={playerId} disabled={!move} onClick={onPlayButtonClick}>
        {!move ? t('select.action') : move?.type === MoveType.SpendTokens ? t('jail.choice.free') : t('jail.choice.token')}
      </Button>
    </Dialog>
  )
}

const actionWidth = 40

const flexContainer = css`
  display: flex;
  max-width: 180em;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3em;

  img {
    margin: 1em;
    width: ${actionWidth}em;
    height: ${actionWidth * 220 / 465}em;
    cursor: pointer;
  }
`

const selectedCss = css`
  filter: drop-shadow(0.5em 0 darkgreen) drop-shadow(-0.5em 0 darkgreen) drop-shadow(0 0.5em darkgreen) drop-shadow(0 -0.5em darkgreen);
`

const unavailable = css`
  cursor: auto !important;
  filter: grayscale();
`
