import {countPlayerMeeples} from '../Brigands'
import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import StealGold, {stealGoldMove} from '../moves/StealGold'
import isThief from '../players/IsThief'
import PlayerColor from '../players/PlayerColor'
import {PLAYER_MEEPLES} from '../players/PlayerState'
import ActionType from './ActionType'

export default function steal(game: GameState | GameView, district: District): StealGold[] {
  const moves: StealGold[] = []
  const princeTarget = game.players.find(player => player.color === PlayerColor.White && player.action?.type === ActionType.Steal)?.action?.target
  for (const victim of game.players.filter(player => player.meeples.slice(0, PLAYER_MEEPLES).includes(district))) {
    if (!isThief(victim) || victim.gold === 0) continue
    let gold = victim.gold
    if (victim.color === princeTarget) {
      const amount = Math.min(5, victim.gold)
      moves.push(stealGoldMove(PlayerColor.White, amount, district, victim.color))
      gold -= amount
    }
    if (gold === 0) continue
    const thieves = game.players.filter(player =>
      player.color !== PlayerColor.White && player.action?.type === ActionType.Steal && player.action.target === victim.color
    )
    if (thieves.length === 1) {
      moves.push(stealGoldMove(thieves[0].color, Math.min(gold, 3 * countPlayerMeeples(thieves[0], district)), district, victim.color))
    } else {
      const meeples = thieves.reduce((sum, thief) => sum + countPlayerMeeples(thief, district), 0)
      if (meeples <= 0) continue
      const goldPerMeeple = Math.min(3, Math.floor(gold / meeples))
      if (goldPerMeeple === 0) continue
      for (const thief of thieves) {
        moves.push(stealGoldMove(thief.color, goldPerMeeple * countPlayerMeeples(thief, district), district, victim.color))
      }
    }
  }
  return moves
}
