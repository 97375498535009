import {countPlayerMeeples} from '../Brigands'
import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import Phase from '../Phase'
import isThief from '../players/IsThief'
import {STOCK} from '../players/PlayerCommon'
import Move from './Move'
import MoveType from './MoveType'

type MoveOnNextPhase = {
  type: MoveType.MoveOnNextPhase
}

export default MoveOnNextPhase

export const moveOnNextPhaseMove: MoveOnNextPhase = {type: MoveType.MoveOnNextPhase}

export function moveOnNextPhase(state: GameState | GameView) {
  switch (state.phase) {
    case Phase.NewDay:
      state.phase = Phase.Planning
      if (state.players.length === 2) {
        const inJail = state.players.map(player => countPlayerMeeples(player, District.Jail))
        if (inJail[0] > inJail[1]) {
          state.players[0].meeples.push(STOCK)
        } else if (inJail[1] > inJail[0]) {
          state.players[1].meeples.push(STOCK)
        }
      }
      break
    case Phase.Planning:
      state.phase = Phase.Solving
      for (const player of state.players) {
        delete player.ready
        if (isThief(player)) {
          player.spied = []
        }
      }
      break
    case Phase.Solving:
      state.phase = Phase.NewDay
      for (const player of state.players) {
        if (isThief(player)) {
          delete player.meeplesStayingInJail
        }
      }
      break
    default:
      console.error('Cannot Move on next phase when game is over!')
  }
}

export function isMoveOnNextPhase(move: Move): move is MoveOnNextPhase {
  return move.type === MoveType.MoveOnNextPhase
}