/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import MoveType from '@gamepark/brigands/moves/MoveType'
import StealGold from '@gamepark/brigands/moves/StealGold'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {useAnimation} from '@gamepark/react-client'
import {Picture} from '@gamepark/react-components'
import Images from '../images/Images'
import {playerPanelX, playerPanelY} from '../utils/styles'
import PlayerPosition from './PlayerPosition'

type Props = {
  playerColor: PlayerColor
  gold: number
  playerPosition: PlayerPosition
}

export function ThiefGold({playerColor, gold, playerPosition}: Props) {
  const goldBeingReceived = useAnimation<StealGold>(animation =>
    animation.move.type === MoveType.StealGold && animation.move.player === playerColor
  )?.move.gold ?? 0
  const coins = getCoins(gold - goldBeingReceived)
  return <>
    {coins.map((coin, index) =>
      <Picture key={coin + '-' + index} src={coin === 5 ? Images.coin5 : coin === 2 ? Images.coin2 : Images.coin1}
               css={[coinCss(coin), coinPosition(playerPosition, index, coin, coins.length)]}/>
    )}
    {gold > 8 && <span css={[totalCss(playerPosition)]}>{gold}</span>}
  </>
}

export function getCoins(gold: number): number[] {
  const coins: number[] = []
  while (gold > 0) {
    if (gold >= 5) {
      coins.push(5)
      gold -= 5
    } else if (gold >= 2) {
      coins.push(2)
      gold -= 2
    } else {
      coins.push(1)
      gold -= 1
    }
  }
  return coins
}

const fontSize = 4

export const coinsX = (playerPosition: PlayerPosition) => playerPanelX(playerPosition) + 5
export const coinsY = (playerPosition: PlayerPosition) => playerPanelY(playerPosition) + 20.5

const totalCss = (playerPosition: PlayerPosition) => css`
  position: absolute;
  font-size: ${fontSize}em;
  left: ${(playerPanelX(playerPosition) + 2) / fontSize}em;
  top: ${(coinsY(playerPosition) + 0.3) / fontSize}em;
  color: white;
  filter: drop-shadow(0 0 0.1em black) drop-shadow(0 0 0.1em black);
`

export const coinSize = (coin: number) => coin === 5 ? 5 : coin === 2 ? 4.8 : 4.7

export const coinCss = (coin: number) => css`
  width: ${coinSize(coin)}em;
  height: ${coinSize(coin)}em;
`

const coinPosition = (playerPosition: PlayerPosition, index: number, coin: number, coins: number) => css`
  position: absolute;
  left: ${coinsX(playerPosition) + index * Math.min(3, 17 / coins)}em;
  top: ${coinsY(playerPosition) + (coinSize(5) - coinSize(coin)) / 2}em;
`
