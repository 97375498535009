/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import PrinceView from '@gamepark/brigands/players/PrinceView'
import {useAnimation, usePlay} from '@gamepark/react-client'
import {Draggable, Picture} from '@gamepark/react-components'
import Images from '../images/Images'
import SkillDropArea, {JUDGE, SPY} from './SkillDropArea'
import {useState} from 'react'
import PatrolJailRulesDialog from '../board/PatrolJailRulesDialog'
import MoveType from '@gamepark/brigands/moves/MoveType'

type Props = {
  prince: PrinceView
  improveSkill: boolean
  openSpyRules: () => void
}

export default function PrinceBoard({prince, improveSkill, openSpyRules}: Props) {
  const play = usePlay()
  const [jailRulesOpen, setJailRulesOpen] = useState(false)
  const animation = useAnimation(animation => animation.move.type === MoveType.UpgradeSkill)
  return (
    <div css={princeBoardStyle}>
      <div css={patrolJailArea} onClick={() => setJailRulesOpen(true)}/>
      <div css={[skillArea, spyArea]} onClick={openSpyRules}>
        <Draggable type={SPY} canDrag={improveSkill && prince.spy < 3} css={[spyPosition(prince.spy), animation && animate(animation.duration)]} drop={play}>
          <Picture src={Images.skillMarker} css={[skillMarkerCss, improveSkill && highlightCss]}/>
        </Draggable>
      </div>
      <div css={[skillArea, judgeArea]} onClick={() => setJailRulesOpen(true)}>
        <Draggable type={JUDGE} canDrag={improveSkill && prince.judge < 3} css={[judgePosition(prince.judge), animation && animate(animation.duration)]}
                   drop={play}>
          <Picture src={Images.skillMarker} css={[skillMarkerCss, improveSkill && highlightCss]}/>
        </Draggable>
      </div>
      {improveSkill && <SkillDropArea spy={prince.spy} judge={prince.judge}/>}
      <PatrolJailRulesDialog open={jailRulesOpen} close={() => setJailRulesOpen(false)} improveSkill={improveSkill}/>
    </div>
  )
}

export const princeBoardWidth = 84
const princeBoardRatio = 579 / 1160
export const princeBoardLeft = 94

const princeBoardStyle = css`
  position: absolute;
  left: ${princeBoardLeft}em;
  bottom: 0;
  width: ${princeBoardWidth}em;
  height: ${princeBoardWidth * princeBoardRatio}em;
  background-image: url(${Images.princeBoard});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  filter: drop-shadow(0 0 0.3em black) drop-shadow(0 0 0.3em black);
`

const patrolJailArea = css`
  position: absolute;
  width: 31.7em;
  height: 7.9em;
  left: 26.2em;
  top: 11.3em;
  border-radius: 1.6em;
  cursor: help;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const skillArea = css`
  position: absolute;
  width: 23.4%;
  height: 78.9%;
  top: 20.8%;
  cursor: help;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const spyArea = css`
  left: 4.6%;
`

const judgeArea = css`
  left: 72.2%;
`

const skillMarkerSize = 5.2

const skillMarkerCss = css`
  width: ${skillMarkerSize}em;
  height: ${skillMarkerSize}em;
  filter: drop-shadow(0em 0.3em 0.2em black);
`

const spyPosition = (level: number) => css`
  position: absolute;
  bottom: ${10 + (level - 1) * 20.6}%;
  left: 61%;
`

const judgePosition = (level: number) => css`
  position: absolute;
  bottom: ${1.8 + level * 18.5}%;
  left: 64%;
`

const highlightKeyframes = keyframes`
  0%, 50% {
    filter: brightness(100%) drop-shadow(0 0 0.2em black);
    transform: scale(1);
  }
  100% {
    filter: brightness(120%) drop-shadow(0 0 0.2em black) drop-shadow(0 0 0.2em black);
    transform: scale(1.1);
  }
`

const highlightCss = css`
  animation: ${highlightKeyframes} 1.5s infinite alternate;
`

const animate = (duration: number) => css`
  transition: bottom ${duration}s ease-in-out;
`
