import canUndo from '@gamepark/brigands/canUndo'
import GameView from '@gamepark/brigands/GameView'
import {chooseAction} from '@gamepark/brigands/moves/ChooseAction'
import {discardDayCard} from '@gamepark/brigands/moves/DiscardDayCard'
import {discardTokenInView} from '@gamepark/brigands/moves/DiscardToken'
import {drawDayCardInView} from '@gamepark/brigands/moves/DrawDayCard'
import {moveOnNextPhase} from '@gamepark/brigands/moves/MoveOnNextPhase'
import MoveType from '@gamepark/brigands/moves/MoveType'
import MoveView from '@gamepark/brigands/moves/MoveView'
import {pass} from '@gamepark/brigands/moves/Pass'
import {placeMeeple} from '@gamepark/brigands/moves/PlaceMeeple'
import {placeTokenInView} from '@gamepark/brigands/moves/PlaceToken'
import {spendTokensInView} from '@gamepark/brigands/moves/SpendTokens'
import {spyDistrictCardsInView} from '@gamepark/brigands/moves/SpyDistrictCards'
import {stealGold} from '@gamepark/brigands/moves/StealGold'
import {takeBackMeeple} from '@gamepark/brigands/moves/TakeBackMeeple'
import {takeTokenInView} from '@gamepark/brigands/moves/TakeToken'
import {throwDices} from '@gamepark/brigands/moves/ThrowDices'
import {upgradeSkill} from '@gamepark/brigands/moves/UpgradeSkill'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {Action, Rules, Undo} from '@gamepark/rules-api'
import SelectMeeple, {SELECT_MEEPLE, selectMeeple} from './localMoves/SelectMeeple'
import SelectToken, {SELECT_TOKEN, selectToken} from './localMoves/SelectToken'
import {isTurnToPlay} from '@gamepark/brigands/Brigands'

type LocalMove = MoveView | SelectMeeple | SelectToken

export default class BrigandsView extends Rules<GameView, MoveView, PlayerColor> implements Undo<GameView, MoveView, PlayerColor> {
  isTurnToPlay(playerId: PlayerColor): boolean {
    return isTurnToPlay(this.state, playerId)
  }

  play(move: LocalMove): MoveView[] {
    if (this.state.nextMoves.length && this.state.nextMoves[0].type === move.type) {
      this.state.nextMoves.shift()
    }
    switch (move.type) {
      case MoveType.TakeToken:
        takeTokenInView(this.state, move)
        break
      case MoveType.DrawDayCard:
        drawDayCardInView(this.state, move)
        break
      case MoveType.MoveOnNextPhase:
        moveOnNextPhase(this.state)
        break
      case MoveType.PlaceMeeple:
        placeMeeple(this.state, move)
        delete this.state.selectedMeeple
        break
      case MoveType.PlaceToken:
        placeTokenInView(this.state, move)
        delete this.state.selectedToken
        break
      case MoveType.Pass:
        pass(this.state, move)
        delete this.state.selectedMeeple
        delete this.state.selectedToken
        break
      case MoveType.ChooseAction:
        chooseAction(this.state, move)
        break
      case MoveType.TakeBackMeeple:
        takeBackMeeple(this.state, move)
        break
      case MoveType.StealGold:
        stealGold(this.state, move)
        break
      case MoveType.DiscardToken:
        discardTokenInView(this.state, move)
        break
      case MoveType.SpendTokens:
        spendTokensInView(this.state, move)
        break
      case MoveType.ThrowDices:
        throwDices(this.state, move)
        break
      case MoveType.DiscardDayCard:
        discardDayCard(this.state, move)
        break
      case MoveType.UpgradeSkill:
        upgradeSkill(this.state, move)
        break
      case MoveType.SpyDistrictCards:
        spyDistrictCardsInView(this.state, move)
        break
      case SELECT_MEEPLE:
        selectMeeple(this.state, move)
        break
      case SELECT_TOKEN:
        selectToken(this.state, move)
        break
    }
    return []
  }

  canUndo(action: Action<MoveView, PlayerColor>, consecutiveActions: Action<MoveView, PlayerColor>[]): boolean {
    return canUndo(action, consecutiveActions, this.state)
  }

  restoreLocalMoves(localState: GameView) {
    this.state.selectedMeeple = localState.selectedMeeple
    this.state.selectedToken = localState.selectedToken
  }
}
