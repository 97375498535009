/** @jsxImportSource @emotion/react */
import {countPlayerMeeples, getCurrentDistrict, patrolInDistrict} from '@gamepark/brigands/Brigands'
import {getPlayerName} from '@gamepark/brigands/BrigandsOptions'
import District from '@gamepark/brigands/districts/District'
import GameView from '@gamepark/brigands/GameView'
import Move from '@gamepark/brigands/moves/Move'
import MoveType from '@gamepark/brigands/moves/MoveType'
import {passMove} from '@gamepark/brigands/moves/Pass'
import {Skill} from '@gamepark/brigands/moves/UpgradeSkill'
import Phase from '@gamepark/brigands/Phase'
import isThief from '@gamepark/brigands/players/IsThief'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {STOCK} from '@gamepark/brigands/players/PlayerCommon'
import {PLAYER_MEEPLES} from '@gamepark/brigands/players/PlayerState'
import {GOLD_TO_WIN} from '@gamepark/brigands/players/ThiefState'
import ThiefView from '@gamepark/brigands/players/ThiefView'
import ActionType from '@gamepark/brigands/tokens/ActionType'
import {Player as PlayerInfo, useAnimation, usePlay, usePlayerId, usePlayers} from '@gamepark/react-client'
import {TFunction} from 'i18next'
import {Trans, useTranslation} from 'react-i18next'
import {districtsName} from './board/DistrictHelpDialog'
import HarborActionHeader from './board/harbor/HarborActionHeader'
import JailActionHeader from './board/jail/JailActionHeader'
import TavernActionHeader from './board/tavern/TavernActionHeader'
import HeaderButton from './HeaderButton'
import {shineEffect} from './utils/styles'
import PassActionHeader from './players/action-wheel/PassActionHeader'
import {Picture} from '@gamepark/react-components'
import {districtIcon} from './board/DistrictTile'
import {css} from '@emotion/react'

type Props = {
  loading: boolean
  game?: GameView
}

export default function HeaderText({loading, game}: Props) {
  const {t} = useTranslation()
  if (loading || !game) return <>{t('Game loading...')}</>

  if (game.phase !== Phase.NewDay || game.deck > 0) {
    return <HeaderOnGoingGameText game={game}/>
  } else {
    return <HeaderGameOverText game={game}/>
  }
}

function getPseudo(player: PlayerColor, players: PlayerInfo<PlayerColor>[], t: TFunction): string {
  return players.find(p => p.id === player, t)!.name ?? getPlayerName(player, t)
}

function HeaderOnGoingGameText({game}: { game: GameView }) {
  const {t} = useTranslation()
  const play = usePlay()
  const playerId = usePlayerId<PlayerColor>()
  const player = game.players.find(p => p.color === playerId)
  const players = usePlayers<PlayerColor>()
  const animation = useAnimation<Move>()

  switch (game.phase) {
    case Phase.NewDay:
      if (animation?.move.type !== MoveType.DrawDayCard) {
        return <>{t('new.day.tokens')}</>
      } else {
        return <>{t('new.day.card')}</>
      }
    case Phase.Planning:
      if (player && !player.ready) {
        if (player.meeples.includes(STOCK)) {
          return <>{t('planning.place.meeples')}</>
        } else {
          return <Trans defaults="planning.validate" components={[
            <HeaderButton css={shineEffect} onClick={() => play(passMove(player.color))} color={player.color}/>
          ]}/>
        }
      } else {
        const awaitedPlayers = game.players.filter(p => !p.ready)
        if (awaitedPlayers.length > 1) {
          return <>{t('planning.wait')}</>
        } else if (awaitedPlayers.length === 1) {
          return <>{t('planning.wait.one', {player: getPseudo(awaitedPlayers[0].color, players, t)})}</>
        } else {
          return <>{t('planning.reveal')}</>
        }
      }
    case Phase.Solving:
      return <><Picture css={districtIconCss} src={districtIcon[getCurrentDistrict(game)]}/><SolvingPhaseHeaderText game={game}/></>
  }
}

function SolvingPhaseHeaderText({game}: { game: GameView }) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()
  const player = game.players.find(p => p.color === playerId)
  const players = usePlayers<PlayerColor>()
  const animation = useAnimation<Move>()

  const district = getCurrentDistrict(game)
  const districtName = districtsName[district](t)

  const playersWithToken = game.players.filter(player => player.tokens.includes(district) && !player.action)
  if (playersWithToken.length > 0) {
    const player = playersWithToken.find(player => player.color === playerId)
    if (player) {
      return <PassActionHeader district={district}/>
    } else if (playersWithToken.length === 1) {
      return <>{t('action.choice', {player: getPseudo(playersWithToken[0].color, players, t), district: districtName})}</>
    } else {
      return <>{t('action.choices', {district: districtName})}</>
    }
  }

  switch (animation?.move.type) {
    case MoveType.TakeBackMeeple:
      if (playerId === animation.move.player) {
        return <>{t('go.home.you', {district: districtName})}</>
      } else {
        return <>{t('go.home.other', {player: getPseudo(animation.move.player, players, t), district: districtName})}</>
      }
    case MoveType.DiscardToken:
      if (playerId === animation.move.player) {
        return <>{t('discard.token.you', {district: districtName})}</>
      } else {
        return <>{t('discard.token.other', {player: getPseudo(animation.move.player, players, t), district: districtName})}</>
      }
    case MoveType.DiscardDayCard:
      return <>{t('day.card.discard', {district: districtName})}</>
  }

  const playersWithAction = game.players.filter(player => player.action)
  if (playersWithAction.length > 0) {
    switch (animation?.move.type) {
      case MoveType.SpyDistrictCards:
        if (playerId === PlayerColor.White) {
          return <>{t('spy.you', {
            brigand: getPseudo(animation.move.thief, players, t),
            spy: animation.move.spy,
            district: districtName
          })}</>
        } else {
          return <>{t('spy.prince', {
            prince: getPseudo(PlayerColor.White, players, t),
            brigand: getPseudo(animation.move.thief, players, t),
            spy: animation.move.spy,
            district: districtName
          })}</>
        }
      case MoveType.StealGold:
        if (playerId === animation.move.player) {
          return <>{t('steal.you', {victim: getPseudo(animation.move.victim!, players, t), gold: animation.move.gold, district: districtName})}</>
        } else if (playerId === animation.move.victim) {
          return <>{t('steal.by', {player: getPseudo(animation.move.player, players, t), gold: animation.move.gold, district: districtName})}</>
        } else {
          return <>{t('steal.other', {
            player: getPseudo(animation.move.player, players, t),
            victim: getPseudo(animation.move.victim!, players, t),
            gold: animation.move.gold,
            district: districtName
          })}</>
        }
      case MoveType.PlaceMeeple:
        const movingPlayer = animation.move.player
        if (movingPlayer === PlayerColor.White) {
          if (animation.move.district === District.Jail) {
            if (movingPlayer === playerId) {
              return <>{t('move.patrol.jail.you', {district: districtName})}</>
            } else {
              return <>{t('move.patrol.jail.other', {prince: getPseudo(movingPlayer, players, t), district: districtName})}</>
            }
          } else {
            if (movingPlayer === playerId) {
              return <>{t('move.patrol.you', {district: districtName})}</>
            } else {
              return <>{t('move.patrol.other', {prince: getPseudo(movingPlayer, players, t), district: districtName})}</>
            }
          }
        }
        const player = game.players.find(player => player.color === movingPlayer)
        if (player?.action?.type === ActionType.Move) {
          if (playerId === movingPlayer) {
            return <>{t('move.team.you', {district: districtName})}</>
          } else {
            return <>{t('move.team.other', {brigand: getPseudo(movingPlayer, players, t), district: districtName})}</>
          }
        } else {
          const pushers = game.players.filter(player => player.action?.type === ActionType.Push && player.action.target === movingPlayer)
          if (playerId === movingPlayer) {
            if (pushers.length === 1) {
              return <>{t('push.you', {brigand: getPseudo(pushers[0].color, players, t), district: districtName})}</>
            } else {
              return <>{t('push.you.many', {district: districtName})}</>
            }
          } else {
            if (pushers.length === 1) {
              return <>{t('push.other', {
                victim: getPseudo(movingPlayer, players, t), brigand: getPseudo(pushers[0].color, players, t), district: districtName
              })}</>
            } else {
              return <>{t('push.other.many', {victim: getPseudo(movingPlayer, players, t), district: districtName})}</>
            }
          }
        }
    }
  }

  if (animation?.move.type === MoveType.PlaceMeeple && animation.move.district === District.Jail) {
    return <>{t('send.jail', {district: districtName})}</>
  } else if (animation?.move.type === MoveType.StealGold) {
    if (district === District.Jail) {
      if (playerId === animation.move.victim) {
        return <>{t('judge.pay.you', {gold: animation.move.gold})}</>
      } else {
        return <>{t('judge.pay.brigand', {brigand: getPseudo(animation.move.victim!, players, t), gold: animation.move.gold})}</>
      }
    }
    if (district === District.Tavern && animation.move.gold < 0) {
      return <>{animation.move.player === playerId ?
        t('tavern.bet.you', {gold: -animation.move.gold})
        : t('tavern.bet.brigand', {brigand: getPseudo(animation.move.player, players, t), gold: -animation.move.gold})
      }</>
    } else {
      return <>{animation.move.player === playerId ?
        t('district.steal.you', {gold: animation.move.gold, district: districtName})
        : t('district.steal.brigand', {
          brigand: getPseudo(animation.move.player, players, t), gold: animation.move.gold, district: districtName
        })
      }</>
    }
  } else if (animation?.move.type === MoveType.ThrowDices) {
    if (district === District.Tavern) {
      return <>{animation.move.player === playerId ?
        t('tavern.roll.you', {dice: animation.move.dices})
        : t('tavern.roll.brigand', {
          brigand: getPseudo(animation.move.player!, players, t), dice: animation.move.dices
        })
      }</>
    } else {
      return <>{t('district.roll', {dices: animation.move.dices, district: districtName})}</>
    }
  } else if (animation?.move.type === MoveType.TakeToken) {
    return <>{animation.move.player === playerId ?
      t('district.token.you', {district: districtName})
      : t('district.token.player', {district: districtName, player: getPseudo(animation.move.player!, players, t)})
    }</>
  } else if (animation?.move.type === MoveType.SpendTokens) {
    return <>{animation.move.player === playerId ?
      t('district.spend.tokens.you', {district: districtName, tokens: animation.move.tokens})
      : t('district.spend.tokens.player', {district: districtName, tokens: animation.move.tokens, player: getPseudo(animation.move.player, players, t)})
    }</>
  }

  if (district === District.Jail) {
    const judge = patrolInDistrict(game, district)
    if (judge) {
      switch (animation?.move.type) {
        case MoveType.UpgradeSkill:
          if (playerId === PlayerColor.White) {
            return <>{animation.move.skill === Skill.Spy ? t('upgrade.spy.you') : t('upgrade.judge.you')}</>
          } else {
            return <>{
              animation.move.skill === Skill.Spy ?
                t('upgrade.spy.prince', {prince: getPseudo(PlayerColor.White, players, t)})
                : t('upgrade.judge.prince', {prince: getPseudo(PlayerColor.White, players, t)})
            }</>
          }
      }
      if (playerId === PlayerColor.White) {
        return <>{t('upgrade.skill.you')}</>
      } else {
        return <>{t('upgrade.skill.prince', {prince: getPseudo(PlayerColor.White, players, t)})}</>
      }
    }
    if (player && isThief(player) && !patrolInDistrict(game, district)) {
      const meeplesStayingInJail = player.meeplesStayingInJail ?? 0
      if (countPlayerMeeples(player, district) > meeplesStayingInJail) {
        return <JailActionHeader player={player}/>
      }
    }
    const thieves = game.players.filter(player => isThief(player) && countPlayerMeeples(player, district) > (player.meeplesStayingInJail ?? 0))
    if (thieves.length === 1) {
      return <>{t('jail.choice.brigand', {brigand: getPseudo(thieves[0].color, players, t)})}</>
    } else {
      return <>{t('jail.choice.many')}</>
    }
  } else {
    if (player && isThief(player) && player.meeples.slice(0, PLAYER_MEEPLES).includes(district)
      && !player.tokens.includes(district) && !patrolInDistrict(game, district)) {
      if (district === District.Tavern) {
        return <TavernActionHeader player={player}/>
      } else if (district === District.Harbor) {
        return <HarborActionHeader player={player} dayCard={game.dayCards.includes(district)}/>
      }
    }
    const thieves = game.players.filter(player => player.meeples.slice(0, PLAYER_MEEPLES).includes(district))
    if (thieves.length === 1) {
      return <>{t('district.choice.brigand', {district: districtName, brigand: getPseudo(thieves[0].color, players, t)})}</>
    } else {
      return <>{t('district.choice.many', {district: districtName})}</>
    }
  }
}

function HeaderGameOverText({game}: { game: GameView }) {
  const {t} = useTranslation()
  const playerId = usePlayerId<PlayerColor>()
  const players = usePlayers<PlayerColor>()
  const bestThieves = game.players.reduce<ThiefView[]>((bestThieves, player) => {
    if (!isThief(player)) return bestThieves
    if (!bestThieves.length || player.gold > bestThieves[0].gold) return [player]
    if (player.gold === bestThieves[0].gold) bestThieves.push(player)
    return bestThieves
  }, [])
  if (game.players.length > 2 && bestThieves[0].gold < GOLD_TO_WIN) {
    if (playerId === PlayerColor.White) {
      return <> {t('win.prince.you')} </>
    } else {
      return <> {t('win.prince', {player: getPseudo(PlayerColor.White, players, t)})} </>
    }
  } else {
    switch (bestThieves.length) {
      case 1:
        if (playerId === bestThieves[0].color) {
          return <> {t('win.thief.you')} </>
        } else {
          return <> {t('win.thief', {player: getPseudo(bestThieves[0].color, players, t)})} </>
        }
      case 2:
        if (bestThieves.some(thief => thief.color === playerId)) {
          return <> {t('tie.you', {
            player: getPseudo(bestThieves.find(thief => thief.color !== playerId)!.color, players, t)
          })} </>
        } else {
          return <> {t('tie', {
            player1: getPseudo(bestThieves[0].color, players, t),
            player2: getPseudo(bestThieves[1].color, players, t)
          })} </>
        }
      default:
        if (bestThieves.some(thief => thief.color === playerId)) {
          return <> {t('tie.3+.you', {otherWinners: bestThieves.length - 1})} </>
        } else {
          return <> {t('tie.3+', {winners: bestThieves.length})} </>
        }
    }
  }
}

const districtIconCss = css`
  height: 1.1em;
  vertical-align: text-bottom;
  margin-right: 0.1em;
`
