/** @jsxImportSource @emotion/react */
import District from '@gamepark/brigands/districts/District'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import PlayerView from '@gamepark/brigands/players/PlayerView'
import {useAnimation, usePlay} from '@gamepark/react-client'
import {selectMeepleMove} from '../localMoves/SelectMeeple'
import {noPointerEvents, positionAbsolute} from '../utils/styles'
import DraggableMeeple from './meeples/DraggableMeeple'
import usePlayPlaceMeeple from './meeples/usePlayPlaceMeeple'
import {isMeepleAnimation, transitionMeeple, translateMeeple} from './PlayerMeeples'
import PlayerPosition from './PlayerPosition'

type Props = {
  player: PlayerView
  playerPosition: PlayerPosition
  city: District[]
  selectedMeeple?: number
  canPlaceMeeple?: boolean
}

export function MyMeeples({player, playerPosition, city, selectedMeeple, canPlaceMeeple}: Props) {
  const play = usePlay()
  const playPlaceMeeple = usePlayPlaceMeeple()
  const animation = useAnimation(animation => isMeepleAnimation(animation) && animation.move.player === player.color)
  return <>
    {player.meeples.map((location, meeple) => {
      const meepleColor = meeple >= 3 ? PlayerColor.White : player.color
      return <DraggableMeeple key={meeple} meepleColor={meepleColor} meeple={meeple} location={location} selected={selectedMeeple === meeple}
                              canDrag={canPlaceMeeple && location !== District.Jail}
                              preTransform={translateMeeple(player.color, playerPosition, meeple, location, city)}
                              onClick={() => canPlaceMeeple && location !== District.Jail && play(selectMeepleMove(selectedMeeple === meeple ? undefined : meeple), {local: true})}
                              drop={({district}) => playPlaceMeeple(meeple, district)}
                              css={[positionAbsolute, animation && transitionMeeple(animation.duration), !!location && !canPlaceMeeple && noPointerEvents]}/>
    })}
  </>
}
