import {arrestEveryone} from '../Brigands'
import Move from '../moves/Move'
import {stealGoldMove} from '../moves/StealGold'
import District from './District'
import {DistrictRules} from './DistrictRules'

export default class Palace extends DistrictRules {
  district = District.Palace

  getAutomaticMoves(): Move[] {
    const moves: Move[] = []
    const meeples = this.countMeeples()
    if (meeples >= 3) {
      moves.push(...arrestEveryone(this.state, this.district))
    } else {
      const gainPerMeeple = this.hasDayCard() ? 8 : 5
      for (const player of this.state.players) {
        const playerMeeples = this.countPlayerMeeples(player)
        if (playerMeeples > 0) {
          moves.push(stealGoldMove(player.color, playerMeeples * gainPerMeeple, this.district))
        }
      }
      moves.push(...this.takeBackMeeplesMoves())
    }
    return moves
  }
}