/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import District from '@gamepark/brigands/districts/District'
import ChooseAction from '@gamepark/brigands/moves/ChooseAction'
import MoveType from '@gamepark/brigands/moves/MoveType'
import Phase from '@gamepark/brigands/Phase'
import isPrince from '@gamepark/brigands/players/IsPrince'
import {STOCK, TokenLocation} from '@gamepark/brigands/players/PlayerCommon'
import {PLAYER_MEEPLES} from '@gamepark/brigands/players/PlayerState'
import PlayerView from '@gamepark/brigands/players/PlayerView'
import {useAnimation} from '@gamepark/react-client'
import {playerPanelX, playerPanelY} from '../utils/styles'
import ActionWheel, {arcCss, getActionAngle} from './action-wheel/ActionWheel'
import {MyActionTokens} from './MyActionTokens'
import {MyMeeples} from './MyMeeples'
import {PlayerActionTokens} from './PlayerActionTokens'
import {PlayerMeeples} from './PlayerMeeples'
import PlayerPanel from './PlayerPanel'
import PlayerPosition from './PlayerPosition'
import PrinceBoard from './PrinceBoard'
import {ThiefGold} from './ThiefGold'

type Props = {
  player: PlayerView
  position: PlayerPosition
  me: boolean
  city: District[]
  phase: Phase
  currentDistrict?: District
  selectedMeeple?: number
  selectedToken?: TokenLocation
  openSpyRules: () => void
  gameOver: boolean
}

export function PlayerDisplay({player, position, me, city, phase, currentDistrict, selectedMeeple, selectedToken, openSpyRules, gameOver}: Props) {
  const choosingAction = useAnimation<ChooseAction>(animation => animation.move.type === MoveType.ChooseAction && animation.move.player === player.color)
  return <>
    <PlayerPanel player={player} position={position} gameOver={gameOver}/>
    {isPrince(player) ?
      <PrinceBoard prince={player} improveSkill={me && currentDistrict === District.Jail && player.meeples.includes(currentDistrict)}
                   openSpyRules={openSpyRules}/>
      : <ThiefGold playerColor={player.color} gold={player.gold} playerPosition={position}/>
    }
    {me ?
      <MyActionTokens player={player.color} playerPosition={position} tokens={player.tokensPlace} city={city} selectedToken={selectedToken}
                      canPlaceToken={me && phase === Phase.Planning && canPlaceToken(player)}/>
      :
      <PlayerActionTokens player={player.color} playerPosition={position} tokens={player.tokensPlace} city={city}/>
    }
    {me ?
      <MyMeeples player={player} playerPosition={position} city={city} selectedMeeple={selectedMeeple} canPlaceMeeple={me && phase === Phase.Planning}/>
      :
      <PlayerMeeples player={player.color} playerPosition={position} meeples={player.meeples} city={city}/>
    }
    {choosingAction &&
        <div css={actionWheelAnimation(position, choosingAction.duration)}>
            <ActionWheel playerColor={player.color}/>
            <div css={[wheelAction, arcCss(...getActionAngle(player.color, choosingAction.move.action))]}/>
        </div>
    }
  </>
}

export function canPlaceToken(player: PlayerView) {
  return !player.ready && player.meeples.slice(0, PLAYER_MEEPLES).some(meeple => meeple !== STOCK && !player.tokens.includes(meeple))
}

const actionWheelKeyframes = (position: PlayerPosition) => keyframes`
  from {
    transform: translate(${playerPanelX(position) - 12}em, ${playerPanelY(position) - 10}em) scale(0.2);
  }
  to {
    transform: translate(111em, 20em);
  }
`

const actionWheelAnimation = (position: PlayerPosition, duration: number) => css`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
  animation: ${actionWheelKeyframes(position)} ${duration / 4}s ease-in-out forwards;
`

const wheelAction = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 128, 0, 0.5);
  border-radius: 50%;
`
