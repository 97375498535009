/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import MoveType from '@gamepark/brigands/moves/MoveType'
import StealGold from '@gamepark/brigands/moves/StealGold'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {useAnimation, usePlayerId} from '@gamepark/react-client'
import {Picture} from '@gamepark/react-components'
import Images from '../images/Images'
import PlayerPosition from './PlayerPosition'
import {coinCss, coinsX, coinsY, getCoins} from './ThiefGold'

type Props = {
  getPlayerPosition: (playerColor: PlayerColor, playerId?: PlayerColor) => PlayerPosition
}

export function GoldAnimation({getPlayerPosition}: Props) {
  const animation = useAnimation<StealGold>(animation => animation.move.type === MoveType.StealGold)
  const playerId = usePlayerId<PlayerColor>()
  if (!animation) return null
  const {move, duration} = animation
  const playerFrom = move.gold < 0 ? move.player : move.victim ?? PlayerColor.White
  const playerTo = move.gold > 0 ? move.player : PlayerColor.White
  const positionFrom = getPlayerPosition(playerFrom, playerId)
  const positionTo = getPlayerPosition(playerTo, playerId)
  return <>
    {getCoins(Math.abs(animation.move.gold)).map((coin, index, coins) => {
        return <Picture key={coin + '-' + index} src={coin === 5 ? Images.coin5 : coin === 2 ? Images.coin2 : Images.coin1}
                        css={[coinCss(coin), coinAnimation(duration, index, coins.length, positionFrom, positionTo)]}/>
      }
    )}
  </>
}

const coinKeyframes = (origin: PlayerPosition, destination: PlayerPosition) => keyframes`
  from {
    transform: translate(${coinsX(origin)}em, ${coinsY(origin)}em);
  }

  to {
    transform: translate(${coinsX(destination)}em, ${coinsY(destination)}em);
  }
`

const coinAnimation = (duration: number, index: number, coins: number, origin: PlayerPosition, destination: PlayerPosition) => css`
  position: absolute;
  top: 0;
  left: 0;
  animation: ${duration * 0.7}s ${coinKeyframes(origin, destination)} ${coins > 1 ? index * duration * 0.3 / (coins - 1) : 0}s both ease-in-out;
`