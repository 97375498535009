import GameState from '../GameState'
import GameView from '../GameView'
import isPrince from '../players/IsPrince'
import PrinceState from '../players/PrinceState'
import PrinceView from '../players/PrinceView'
import judge from '../skills/Judge'
import MoveType from './MoveType'

type UpgradeSkill = {
  type: MoveType.UpgradeSkill
  skill: Skill
}

export default UpgradeSkill

export enum Skill {
  Spy = 1, Judge
}

export function upgradeSkillMove(skill: Skill): UpgradeSkill {
  return {type: MoveType.UpgradeSkill, skill}
}

export function upgradeSkill(state: GameState | GameView, move: UpgradeSkill) {
  const prince = state.players.find(isPrince) as PrinceState | PrinceView
  switch (move.skill) {
    case Skill.Spy:
      prince.spy++
      break
    case Skill.Judge:
      prince.judge++
      break
  }
  state.nextMoves.push(...judge(state))
}
