import {countMeeples, countPlayerMeeples} from '../Brigands'
import GameState from '../GameState'
import GameView from '../GameView'
import {discardDayCardMove} from '../moves/DiscardDayCard'
import Move from '../moves/Move'
import SpendTokens from '../moves/SpendTokens'
import StealGold, {stealGoldMove} from '../moves/StealGold'
import {takeBackAllDistrictMeeplesMoves, takeBackMeepleMove} from '../moves/TakeBackMeeple'
import TakeToken from '../moves/TakeToken'
import {ThrowDicesRandomized} from '../moves/ThrowDices'
import isThief from '../players/IsThief'
import PlayerColor from '../players/PlayerColor'
import PlayerState from '../players/PlayerState'
import PlayerView from '../players/PlayerView'
import District from './District'

export abstract class DistrictRules {
  state: GameState | GameView
  abstract district: District

  constructor(state: GameState | GameView) {
    this.state = state
  }

  getAutomaticMoves(): Move[] {
    return []
  }

  takeBackMeeplesMoves(): Move[] {
    const moves: Move[] = takeBackAllDistrictMeeplesMoves(this.state, this.district)
    if (this.hasDayCard()) {
      moves.push(discardDayCardMove(this.district))
    }
    return moves
  }

  takeBackMeeple(playerId: PlayerColor) {
    const player = this.state.players.find(player => player.color === playerId)!
    this.state.nextMoves.push(takeBackMeepleMove(player.color, player.meeples.indexOf(this.district)))
    if (this.hasDayCard() && this.countMeeples() === 1) {
      this.state.nextMoves.push(discardDayCardMove(this.district))
    }
  }

  countMeeples() {
    return countMeeples(this.state, this.district)
  }

  countPlayerMeeples(player: PlayerState | PlayerView) {
    return countPlayerMeeples(player, this.district)
  }

  isTurnToPlay(player: PlayerState): boolean {
    return player.meeples.includes(this.district)
  }

  getLegalMoves(_player: PlayerState): Move[] {
    return []
  }

  hasDayCard() {
    return this.state.dayCards.includes(this.district)
  }

  shareGold(gold: number): Move[] {
    const thieves = this.state.players.filter(isThief).filter(player => player.meeples.includes(this.district))
    if (thieves.length === 1) {
      return [stealGoldMove(thieves[0].color, gold, this.district)]
    } else {
      const meeples = this.countMeeples()
      const meepleShare = Math.floor(gold / meeples)
      if (meepleShare === 0) return []
      return thieves.map(thief => stealGoldMove(thief.color, this.countPlayerMeeples(thief) * meepleShare, this.district))
    }
  }

  onTakeToken(_move: TakeToken) {
  }

  onSpendTokens(_move: SpendTokens) {
  }

  onStealGold(_move: StealGold) {
  }

  onThrowDices(_move: ThrowDicesRandomized) {
  }
}