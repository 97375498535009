/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {Picture} from '@gamepark/react-components'
import {PictureAttributes} from '@gamepark/react-components/dist/Picture/Picture'
import Images from '../../images/Images'

type Props = {
  meepleColor: PlayerColor
} & PictureAttributes

export default function Meeple({meepleColor, ...props}: Props) {
  return <Picture src={meepleImage[meepleColor]} css={[meepleCss, meepleColor === PlayerColor.White ? patrolCss : thiefCss]} {...props}/>
}

const meepleImage: Record<PlayerColor, string> = {
  [PlayerColor.White]: Images.meepleWhite,
  [PlayerColor.Blue]: Images.meepleBlue,
  [PlayerColor.Red]: Images.meepleRed,
  [PlayerColor.Green]: Images.meepleGreen,
  [PlayerColor.Purple]: Images.meeplePurple,
  [PlayerColor.Yellow]: Images.meepleYellow
}

export const meepleWidth = 4
export const thiefHeight = meepleWidth * 335 / 248
export const patrolHeight = meepleWidth * 389 / 248

const meepleCss = css`
  width: ${meepleWidth}em;
  filter: drop-shadow(0 0 0.2em black);
`

const thiefCss = css`
  height: ${thiefHeight}em;
`

const patrolCss = css`
  height: ${patrolHeight}em;
`
