import GameView from '@gamepark/brigands/GameView'
import MoveType from '@gamepark/brigands/moves/MoveType'
import MoveView from '@gamepark/brigands/moves/MoveView'
import Phase from '@gamepark/brigands/Phase'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import {Animations} from '@gamepark/react-client'
import AnimationContext from '@gamepark/react-client/dist/animations/AnimationContext'
import ActionType from '@gamepark/brigands/tokens/ActionType'

export default class BrigandsAnimations extends Animations<GameView, MoveView, PlayerColor> {
  override getPreDuration(move: MoveView, {playerId, state}: AnimationContext<GameView, MoveView, PlayerColor>): number {
    switch (move.type) {
      case MoveType.DrawDayCard:
        return 3
      case MoveType.MoveOnNextPhase:
        return state.phase === Phase.Planning ? 3 : 0
      case MoveType.ThrowDices:
        return 2
      case MoveType.DiscardDayCard:
        return 1
      case MoveType.ChooseAction:
        return move.player === playerId || move.action.type === ActionType.Pass ? 0 : 3
      default:
        return 0
    }
  }

  override getPostDuration(move: MoveView, {playerId, state}: AnimationContext<GameView, MoveView, PlayerColor>): number {
    switch (move.type) {
      case MoveType.PlaceMeeple:
      case MoveType.PlaceToken:
        if (state.phase === Phase.Planning) {
          return move.player === playerId ? 0 : 1.5
        } else {
          return 1.5
        }
      case MoveType.TakeToken:
      case MoveType.TakeBackMeeple:
      case MoveType.DiscardToken:
      case MoveType.SpendTokens:
        return 1.5
      case MoveType.StealGold:
        return 2
      case MoveType.UpgradeSkill:
        return 1
      case MoveType.SpyDistrictCards:
        return 2
      default:
        return 0
    }
  }
}
