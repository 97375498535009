import GameState from '../GameState'
import GameView from '../GameView'
import PlayerColor from '../players/PlayerColor'
import MoveType from './MoveType'

type Pass = {
  type: MoveType.Pass
  playerId: PlayerColor
}

export default Pass

export function passMove(playerId: PlayerColor): Pass {
  return {type: MoveType.Pass, playerId}
}

export function pass(state: GameState | GameView, move: Pass) {
  const player = state.players.find(p => p.color === move.playerId)!
  player.ready = true
}
