/** @jsxImportSource @emotion/react */
import {css, keyframes} from '@emotion/react'
import District from '@gamepark/brigands/districts/District'
import DiscardDayCard from '@gamepark/brigands/moves/DiscardDayCard'
import {DrawDayCardView} from '@gamepark/brigands/moves/DrawDayCard'
import MoveType from '@gamepark/brigands/moves/MoveType'
import {useAnimation} from '@gamepark/react-client'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {cityCenterLeft, cityCenterTop, dialogCloseIcon, dialogCss} from '../utils/styles'
import DayCard, {dayCardHeight, dayCardWidth} from './DayCard'
import {districtAngle} from './DistrictTile'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import {Dialog} from '@gamepark/react-components'
import {TFunction} from 'i18next'

type Props = {
  deck: number
  dayCards: District[]
  city: District[]
}

export default function DayCards({dayCards, deck, city}: Props) {
  const {t} = useTranslation()
  const drawing = useAnimation<DrawDayCardView>(animation => animation.move.type === MoveType.DrawDayCard)
  const discarding = useAnimation<DiscardDayCard>(animation => animation.move.type === MoveType.DiscardDayCard)
  const [helpOpen, setHelpOpen] = useState<District | boolean>(false)
  return (
    <>
      {dayCards.map(district =>
        <DayCard key={district} district={district} onClick={() => setHelpOpen(district)}
                 css={[districtPosition(city.indexOf(district)), discarding && discarding.move.district === district && fadeOut(discarding.duration)]}/>
      )}
      <div css={drawPileSpace} onClick={() => setHelpOpen(true)}/>
      {[...Array(deck)].map((_, index) => {
          const drawingDistrict = drawing && index === deck - 1 ? drawing.move.district : undefined
          return <DayCard key={'deck' + index} district={drawingDistrict} onClick={() => setHelpOpen(true)}
                          css={drawingDistrict ? districtPosition(city.indexOf(drawingDistrict), drawing?.duration) : deckPosition(index)}/>
        }
      )}
      <Dialog css={dialogCss} open={!!helpOpen} onBackdropClick={() => setHelpOpen(false)}>
        <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={() => setHelpOpen(false)}/>
        <h2>{t('day.cards')}</h2>
        <p>{t('day.cards.rules')}</p>
        <p><strong>{deck > 0 ? t('day.cards.left', {cards: deck}) : t('day.cards.empty')}</strong></p>
        {typeof helpOpen === 'number' && <>
          <DayCard district={helpOpen} css={helpCardCss}/>
          <p>{cardRules[helpOpen](t)}</p>
        </>}
      </Dialog>
    </>
  )
}

const deckPosition = (index: number) => css`
  position: absolute;
  left: ${cityCenterLeft - dayCardWidth / 2}em;
  top: ${cityCenterTop - dayCardHeight / 2}em;
  transform: translate(${-index * 0.2}em, ${29.5 - index * 0.2}em) rotateY(-180deg);
`

const districtPosition = (districtIndex: number, duration = 0) => css`
  position: absolute;
  left: ${cityCenterLeft - dayCardWidth / 2}em;
  top: ${cityCenterTop - dayCardHeight / 2}em;
  transform: translate(${districtX(districtIndex)}em, ${districtY(districtIndex)}em) rotate(${districtAngle(districtIndex)}deg);
  transition: transform ${duration}s ease-in-out;
`

const districtRadius = 39

function districtX(districtIndex: number) {
  const radius = districtRadius
  const angle = districtAngle(districtIndex) * Math.PI / 180
  return -Math.cos(angle) * radius
}

function districtY(districtIndex: number) {
  const radius = districtRadius
  const angle = districtAngle(districtIndex) * Math.PI / 180
  return -Math.sin(angle) * radius
}

const fadeOutKeyFrames = keyframes`
  from {
    opacity: 1
  }
  10%, to {
    opacity: 0
  }
`

const fadeOut = (duration: number) => css`
  animation: ${fadeOutKeyFrames} ${duration}s ease-in;
`

const helpCardCss = css`
  display: inline-block;
  transform: rotate(-90deg) scale(1.4);
  font-size: 2em;
`

const drawPileSpace = css`
  position: absolute;
  width: 11em;
  height: 15.8em;
  left: 42em;
  top: 75.8em;
  border-radius: 1em;
  cursor: help;

  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
`

const cardRules: Record<District, (t: TFunction) => string> = {
  [District.Jail]: t => t('jail.rules'),
  [District.Tavern]: t => t('tavern.card.rules'),
  [District.Market]: t => t('market.rules'),
  [District.Harbor]: t => t('harbor.rules', {tokens: 3}),
  [District.CityHall]: t => t('cityHall.rules', {gold: 16}),
  [District.Treasure]: t => t('treasure.rules', {gold: 15}),
  [District.Palace]: t => t('palace.rules', {gold: 8}),
  [District.Convoy]: t => t('convoy.rules', {dice: 2})
}