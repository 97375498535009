/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import District from '@gamepark/brigands/districts/District'
import {Picture} from '@gamepark/react-components'
import {HTMLAttributes} from 'react'
import Images from '../images/Images'

type Props = {
  district?: District
} & HTMLAttributes<HTMLDivElement>

export default function DayCard({district, ...props}: Props) {
  return (
    <div css={[cardCss, !district && backCss]} {...props}>
      {district && <Picture src={cardsImage[district]} css={sideCss}/>}
      <Picture src={Images.dayCardBack} css={[sideCss, backCss]}/>
    </div>
  )
}

export const dayCardWidth = 12.5
export const dayCardHeight = dayCardWidth * 700 / 465

const cardCss = css`
  width: ${dayCardWidth}em;
  height: ${dayCardHeight}em;
  transform-style: preserve-3d;
  cursor: help;

  picture, img {
    transform-style: preserve-3d;
    backface-visibility: hidden;
  }
`

const sideCss = css`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 1em;
  backface-visibility: hidden;
  box-shadow: 0 0 0.3em black, 0 0 0.3em black;
`

const backCss = css`
  transform: rotateY(180deg);
`

const cardsImage: Record<District, string> = {
  [District.Market]: Images.dayCardMarket,
  [District.CityHall]: Images.dayCardCityHall,
  [District.Harbor]: Images.dayCardHarbor,
  [District.Tavern]: Images.dayCardTavern,
  [District.Palace]: Images.dayCardPalace,
  [District.Convoy]: Images.dayCardConvoy,
  [District.Treasure]: Images.dayCardTreasure,
  [District.Jail]: ''
}
