import {arrestEveryone} from '../Brigands'
import Move from '../moves/Move'
import {stealGoldMove} from '../moves/StealGold'
import District from './District'
import {DistrictRules} from './DistrictRules'

export default class Treasure extends DistrictRules {
  district = District.Treasure

  getAutomaticMoves(): Move[] {
    const players = this.state.players.filter(player => player.meeples.includes(this.district))
    if (players.length > 1) {
      return arrestEveryone(this.state, this.district)
    } else {
      const moves: Move[] = [stealGoldMove(players[0].color, this.hasDayCard() ? 15 : 10, this.district)]
      moves.push(...this.takeBackMeeplesMoves())
      return moves
    }
  }
}
