import District from '../districts/District'
import GameState from '../GameState'
import GameView from '../GameView'
import MoveType from './MoveType'

type DrawDayCard = { type: typeof MoveType.DrawDayCard }

export default DrawDayCard

export const drawDayCardMove: DrawDayCard = {type: MoveType.DrawDayCard}

export type DrawDayCardView = DrawDayCard & {
  district: District
}

export function drawDayCard(state: GameState) {
  state.dayCards.push(state.deck.shift()!)
}

export function drawDayCardInView(state: GameView, move: DrawDayCardView) {
  state.dayCards.push(move.district)
  state.deck--
}

export function getDrawEventView(state: GameState): DrawDayCardView {
  return {type: MoveType.DrawDayCard, district: state.deck[0]}
}
