/** @jsxImportSource @emotion/react */
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Dialog, DialogProps} from '@gamepark/react-components'
import {Trans, useTranslation} from 'react-i18next'
import {dialogCloseIcon, dialogCss} from '../utils/styles'
import {faXmark} from '@fortawesome/free-solid-svg-icons/faXmark'
import Button from '../utils/Button'
import {usePlay} from '@gamepark/react-client'
import {Skill, upgradeSkillMove} from '@gamepark/brigands/moves/UpgradeSkill'

type Props = {
  close: () => void
  improveSkill: boolean
} & DialogProps

export default function PatrolJailRulesDialog({close, improveSkill, ...props}: Props) {
  const {t} = useTranslation()
  const play = usePlay()
  return (
    <Dialog css={dialogCss} onBackdropClick={close} {...props}>
      <FontAwesomeIcon icon={faXmark} css={dialogCloseIcon} onClick={close}/>
      <h2>{t('judge.title')}</h2>
      <p><Trans defaults="judge.rules" components={[<strong/>]}/></p>
      {improveSkill &&
          <Button onClick={() => {
            play(upgradeSkillMove(Skill.Judge))
            close()
          }}>
            {t('judge.upgrade')}
          </Button>
      }
    </Dialog>
  )
}
