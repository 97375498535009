/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react'
import {getPlayerName} from '@gamepark/brigands/BrigandsOptions'
import PlayerColor from '@gamepark/brigands/players/PlayerColor'
import ActionType from '@gamepark/brigands/tokens/ActionType'
import TokenAction from '@gamepark/brigands/tokens/TokenAction'
import {usePlayers} from '@gamepark/react-client'
import {Trans, useTranslation} from 'react-i18next'
import {getPlayerColor} from '../PlayerPanel'

type Props = {
  action: TokenAction
  playerId: PlayerColor
}

export default function ActionRules({action, playerId}: Props) {
  const {t} = useTranslation()
  const players = usePlayers()
  const brigand = action.target ? players.find(player => player.id === action.target)?.name ?? getPlayerName(action.target, t) : ''
  switch (action.type) {
    case ActionType.Spy:
      return <p>{t('action.0.rules')}</p>
    case ActionType.GoHome:
      if (playerId === PlayerColor.White) {
        return <p>{t('action.1.prince.rules')}</p>
      } else {
        return <p>{t('action.1.rules')}</p>
      }
    case ActionType.Steal:
      if (playerId === PlayerColor.White) {
        return <p><Trans defaults="action.2.prince.rules" values={{brigand}} components={[<span css={colorCss(action.target!)}/>]}/></p>
      } else {
        return <p><Trans defaults="action.2.rules" values={{brigand}} components={[<span css={colorCss(action.target!)}/>]}/></p>
      }
    case ActionType.Move:
      if (playerId === PlayerColor.White) {
        return <p>{t('action.3.prince.rules')}</p>
      } else {
        return <p>{t('action.3.rules')}</p>
      }
    case ActionType.Push:
      return <p><Trans defaults="action.4.rules" values={{brigand}} components={[<span css={colorCss(action.target!)}/>]}/></p>
    default:
      console.error('Unknown action:', action)
      return null
  }
}

const colorCss = (playerColor: PlayerColor) => css`
  color: ${getPlayerColor(playerColor)};
`
