import {OptionsSpec} from '@gamepark/rules-api'
import {TFunction} from 'i18next'
import GameState from './GameState'
import PlayerColor, {playerColors} from './players/PlayerColor'

export type BrigandsPlayerOptions = { id: PlayerColor }

export type BrigandsOptions = {
  players: BrigandsPlayerOptions[]
}

export function isGameOptions(arg: GameState | BrigandsOptions): arg is BrigandsOptions {
  return typeof (arg as GameState).deck === 'undefined'
}

export const BrigandsOptionsSpec: OptionsSpec<BrigandsOptions> = {
  players: {
    id: {
      label: t => t('Color'),
      values: playerColors,
      valueSpec: color => ({label: t => getPlayerName(color, t)}),
      mandatory: players => players > 2 ? [PlayerColor.White] : [],
      unavailable: players => players === 2 ? [PlayerColor.White] : []
    }
  }
}

export function getPlayerName(playerId: PlayerColor, t: TFunction) {
  switch (playerId) {
    case PlayerColor.White:
      return t('Prince')
    case PlayerColor.Blue:
      return t('Blue Brigand')
    case PlayerColor.Red:
      return t('Red Brigand')
    case PlayerColor.Green:
      return t('Green Brigand')
    case PlayerColor.Purple:
      return t('Purple Brigand')
    case PlayerColor.Yellow:
      return t('Yellow Brigand')
  }
}