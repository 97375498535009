import PlayerColor from './PlayerColor'
import PlayerState from './PlayerState'
import PlayerView from './PlayerView'
import ThiefState from './ThiefState'
import ThiefView from './ThiefView'

export default function isThief(player: PlayerState): player is ThiefState
export default function isThief(player: PlayerView): player is ThiefView
export default function isThief(player: PlayerState | PlayerView): player is ThiefState | ThiefView
export default function isThief(player: PlayerState | PlayerView): player is ThiefState | ThiefView {
  return player.color !== PlayerColor.White
}